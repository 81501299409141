import { Fragment, useState } from "react";
import PropTyps from "prop-types";
import TeamInfo from "../../components/team-info";

const TeamCard = ({ data }) => {
    const [modalShow, setModalShow] = useState(false);
    const onModalHandler = (data) => {
        setModalShow((prev) => !prev);
    };

    return (
        <Fragment>
            <div
                className="team-card"
                onClick={() => onModalHandler(data)}
                onKeyDown={() => onModalHandler(data)}
                role="button"
                tabIndex="0"
            >
                <div className="thumb">
                    <img
                        src={process.env.PUBLIC_URL + data.thumb}
                        alt={data.name}
                        className="img-bottom-border"
                    />
                </div>
                <div className="content">
                    <h3 className="title">{data.name}</h3>
                    <span>{data.designation}</span>
                </div>
            </div>
            <TeamInfo show={modalShow} onClose={onModalHandler} />
        </Fragment>
    );
};

TeamCard.propTypes = {
    data: PropTyps.object,
};

export default TeamCard;
