import React, { useRef } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import HelpContainer from "../containers/help";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const HelpPage = () => {
    const contRef = useRef(null);
    const handleScrollToHeader = () => {
        console.log("scrllheader", contRef);
        if (contRef.current) {
            console.log("scrllheader2");
            // Adjust scrolling to account for the height of the small div
            const headerPosition = contRef.current.offsetTop;
            const divHeight = 0; // Adjust this if your div's height changes
            window.scrollTo({
                top: headerPosition,
                behavior: "smooth",
            });
        }
    };
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Help | NeoGrowth - Lending simplified. Growth amplified" />
                <div className="wrapper">
                    <Header
                        onPressSkipButton={() => {
                            handleScrollToHeader();
                        }}
                    />
                    <PageBanner
                        title="Help"
                        excerpt="Please get in touch and our customer support team <br> will answer all the questions"
                        image="/images/contact/1.png"
                    />
                    <HelpContainer contRef={contRef} />
                    {/* <NewsletterArea /> */}
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default HelpPage;
