import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const FontSizeContext = createContext();

export const FontSizeProvider = ({ children }) => {
    const [fontSize, setFontSize] = useState(0); // Default font size

    const increaseFontSize = () => {
        if (fontSize >= 2) {
            return;
        }
        setFontSize((prev) => prev + 1);
    };
    const decreaseFontSize = () => {
        if (fontSize <= -4) {
            return;
        }
        setFontSize((prev) => Math.max(prev - 1));
    };
    const defaultFontSize = () => setFontSize(0); // Minimum font size

    return (
        <FontSizeContext.Provider
            value={{
                fontSize,
                increaseFontSize,
                decreaseFontSize,
                defaultFontSize,
            }}
        >
            {children}
        </FontSizeContext.Provider>
    );
};

FontSizeProvider.propTypes = {
    children: PropTypes.node,
};
