import React, { useEffect, useRef, useContext, useState } from "react";
import PropTypes from "prop-types";
import { FontSizeContext } from "../../context/fontSizeContext";
import SectionTitle from "../../components/section-title";

const PartnersContainer = ({ data, contRef }) => {
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);

    const [videoSrc, setVideoSrc] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = (data) => {
        if (data == 0) {
            setVideoSrc([
                "Nodal Grievance Redressal Officer",
                "Ritesh Saxena",
                "022-42241220",
                "GRO@smfgindia.com",
                "B Wing, 6th Floor, Supreme Business Park, Powai, Mumbai – 400076",
                "https://www.smfgindiacredit.com/digital-lending-partner.aspx?tab=ngro",
            ]);
        } else {
            setVideoSrc([
                "Grievance Redressal Officer",
                "Mr. Vaseem Khan",
                "+91 91159 98000 / +91 22 3043 3020",
                "Grievance.sme@ambit.co",
                "Kanakia Wall Street - 5th floor, A 506-510, Andheri-Kurla Road, Andheri East, Mumbai, Maharashtra - 400093",
                "https://finvest.ambit.co/",
            ]);
        }
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
        setVideoSrc("");
    };

    // useEffect(() => {
    //     if (isModalOpen) {
    //         setVideoSrc(
    //             "https://www.youtube.com/embed/oUZnCGZpLyU?si=hjVgsQtFHyANoGb6&autoplay=1"
    //         );
    //     } else {
    //         setVideoSrc(""); // Stop video when modal is closed
    //     }
    // }, [isModalOpen]);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);
    }, []);
    return (
        <div className="single-service section-py" ref={contRef}>
            {data.map((single, i) => {
                return (
                    <div className="container custom-container mb-10" key={i}>
                        <div className="row g-0 align-items-center">
                            <div className="col-xl-6 col-lg-8 mx-auto  text-center">
                                <SectionTitle
                                    classOption="title-section"
                                    subTitle=""
                                    title={single.name}
                                    excerptClassOption="mb-5"
                                    excerpt=""
                                />
                            </div>
                            <div className="col-12">
                                <div
                                    id="grid"
                                    className="grid row mb-n7 justify-content-center"
                                >
                                    {single.image.map((image, i) => {
                                        if (!single.isInfo) {
                                            return (
                                                <div
                                                    className="grid-item mb-7 text-center"
                                                    key={i}
                                                >
                                                    <div className="grid-item-padding feature-card bg-light">
                                                        <img
                                                            style={{
                                                                maxHeight:
                                                                    "75px",
                                                            }}
                                                            className="logo"
                                                            src={
                                                                process.env
                                                                    .PUBLIC_URL +
                                                                image
                                                            }
                                                            alt=" feature logo"
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div
                                                    className="grid-item mb-7 text-center"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    key={i}
                                                    onClick={() => {
                                                        openModal(i);
                                                    }}
                                                    aria-hidden="true"
                                                >
                                                    <div className="grid-item-padding feature-card bg-light">
                                                        <img
                                                            style={{
                                                                maxHeight:
                                                                    "75px",
                                                            }}
                                                            className="logo"
                                                            src={
                                                                process.env
                                                                    .PUBLIC_URL +
                                                                image
                                                            }
                                                            alt=" feature logo"
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
            <div
                className={`modal ${isModalOpen ? "show" : ""}`}
                style={{
                    display: isModalOpen ? "block" : "none",
                    background: "#b5c4a875",
                }}
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    style={{
                        borderRadius: "0.75em",
                    }} // Adjust modal width
                >
                    <div className="modal-content">
                        {/* Close button */}
                        <button
                            type="button"
                            className="btn-close custom-close-button"
                            style={{
                                position: "absolute",
                                top: "-15px",
                                right: "-5px",
                                backgroundColor: "#fff",
                                border: "1px solid #ccc",
                                borderRadius: "50%",
                                padding: "5px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                zIndex: 1050,
                                width: "25px",
                                height: "25px",
                            }}
                            aria-label="Close"
                            onClick={closeModal}
                        ></button>

                        <div className="modal-body p-5">
                            <div className="row justify-content-center">
                                <div className="col-md-10">
                                    <h6 className="text-center">
                                        <b>{videoSrc[0]}</b>
                                    </h6>
                                    <table
                                        style={{ border: "2px solid green;" }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        border: "2px solid green;",
                                                    }}
                                                >
                                                    Name
                                                </td>
                                                <td
                                                    style={{
                                                        border: "2px solid green;",
                                                    }}
                                                >
                                                    {videoSrc[1]}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Phone No</td>
                                                <td>{videoSrc[2]}</td>
                                            </tr>
                                            <tr>
                                                <td>Email Id</td>
                                                <td>
                                                    <a href="'mailto: '+{videoSrc[0]}+'">
                                                        {videoSrc[3]}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Adderess</td>
                                                <td>{videoSrc[4]}</td>
                                            </tr>
                                            <tr>
                                                <td>Website URL</td>
                                                <td>
                                                    <a
                                                        href={videoSrc[5]}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {videoSrc[5]}
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

PartnersContainer.propTypes = {
    data: PropTypes.array,
    contRef: PropTypes.any,
};

export default PartnersContainer;
