import React, { useEffect, useRef, useContext, useState } from "react";
import Accordion, {
    AccordionItem,
    AccordionTitle,
    AccordionContent,
} from "../accrodion";
import { FontSizeContext } from "../../context/fontSizeContext";

const AccordionWrap = () => {
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);
    }, []);

    return (
        <>
            <Accordion classOption="accordion-style2 no-bg">
                <h3
                    className="title g-color"
                    ref={(el) => (textRefs.current[0] = el)}
                >
                    <strong>
                        <u>Loan Offerings</u>
                    </strong>
                </h3>
                <p
                    className="question-tag g-color"
                    ref={(el) => (textRefs.current[1] = el)}
                >
                    Are your loans and your business a perfect fit? Read on to
                    find out.
                </p>
                <br></br>
                <AccordionItem id="lo-one">
                    <AccordionTitle id="lo-one">
                        <span className="question-tag">Q 1:</span>
                        What are the types of loans that I can apply for?
                    </AccordionTitle>

                    <AccordionContent id="lo-one">
                        <p
                            ref={(el) => (textRefs.current[2] = el)}
                            className="f-large"
                        >
                            There are 3 types of business loans that we at
                            NeoGrowth offer:
                        </p>
                        <ul
                            ref={(el) => (textRefs.current[3] = el)}
                            className=""
                        >
                            <li>
                                Loans for retailers/shops/merchants using
                                debit/credit card swipe machine
                            </li>
                            <li>
                                Loans to manufacturers/wholesalers/ traders/
                                distributors / retailers selling goods or
                                services to large corporates
                            </li>
                            <li>
                                Loans to manufacturers/wholesalers/ traders/
                                distributors / retailers purchasing goods or
                                services from large corporates
                            </li>
                        </ul>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="lo-two">
                    <AccordionTitle id="lo-two">
                        <span className="question-tag">Q 2:</span>What is the
                        minimum business loan amount that I can avail?
                    </AccordionTitle>
                    <AccordionContent id="lo-two">
                        <p
                            ref={(el) => (textRefs.current[4] = el)}
                            className="f-large"
                        >
                            We provide business loans starting from INR One
                            Lakhs.
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="lo-three">
                    <AccordionTitle id="lo-three">
                        <span className="question-tag">Q 3:</span>What is the
                        maximum loan amount that I can avail?
                    </AccordionTitle>
                    <AccordionContent id="lo-three">
                        <p
                            ref={(el) => (textRefs.current[5] = el)}
                            className="f-large"
                        >
                            Your loan amount is based on your business
                            performance, and a few other criteria that we look
                            at before disbursing the loan. The maximum loan
                            amount that can be availed is INR 75 lakhs.
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="lo-four">
                    <AccordionTitle id="lo-four">
                        <span className="question-tag">Q 4:</span>What is the
                        maximum tenor of business loans at NeoGrowth?
                    </AccordionTitle>
                    <AccordionContent id="lo-four">
                        <p
                            ref={(el) => (textRefs.current[6] = el)}
                            className="f-large"
                        >
                            Our loans extend from 6 months to 36 months
                            depending on the type of loan, and your business
                            performance.
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="lo-five">
                    <AccordionTitle id="lo-five">
                        <span className="question-tag">Q 5:</span>If I sell
                        through retail outlet using POS machine as well as
                        online,can I get a loan from NeoGrowth?
                    </AccordionTitle>
                    <AccordionContent id="lo-five">
                        <p
                            ref={(el) => (textRefs.current[7] = el)}
                            className="f-large"
                        >
                            Yes! NeoGrowth has several success stories having
                            given loans to businesses who do both online and
                            retail sales. We verify your retail and online
                            business performance, and accordingly extend a loan
                            which is a combination of both. The advantage is
                            that you get a loan of higher amount and longer
                            tenure.
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="lo-six">
                    <AccordionTitle id="lo-six">
                        <span className="question-tag">Q 6:</span>Can I
                        pre-close my loan?
                    </AccordionTitle>
                    <AccordionContent id="lo-six">
                        <p
                            ref={(el) => (textRefs.current[8] = el)}
                            className="f-large"
                        >
                            Yes, you have an option of pre-closure at any stage
                            by paying a pre closure amount as per the mutual
                            agreement of the customer and the NeoGrowth
                            representative at the stage of the pre-closure
                            request.
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="lo-seven">
                    <AccordionTitle id="lo-seven">
                        <span className="question-tag">Q 7:</span>In which
                        locations can I avail of a Business Loan?
                    </AccordionTitle>
                    <AccordionContent id="lo-seven">
                        <p
                            ref={(el) => (textRefs.current[9] = el)}
                            className="f-large"
                        >
                            We are currently present in all major cities of
                            India. Our current network is in the following
                            cities: Ahmedabad, Mumbai, Delhi, Bengaluru, Pune,
                            Chennai, Chandigarh, Hyderabad, Jaipur, Kolkata,
                            Lucknow, Vadodara, Madurai, Mysore, Nagpur, Indore,
                            Ludhiana, Vijayawada, Surat, Coimbatore & Vizag.
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="lo-eight">
                    <AccordionTitle id="lo-eight">
                        <span className="question-tag">Q 8:</span>I don’t have a
                        card swipe machine. Can I still apply for loan at
                        NeoGrowth?
                    </AccordionTitle>
                    <AccordionContent id="lo-eight">
                        <p
                            ref={(el) => (textRefs.current[10] = el)}
                            className="f-large"
                        >
                            Yes, you can. We will evaluate your business and
                            check if any of our loan products fit your
                            requirement.
                        </p>
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
            <br></br>
            <br></br>
            <Accordion classOption="accordion-style2 no-bg">
                <h3 className="title g-color">
                    <strong>
                        <u>Loan Process</u>
                    </strong>
                </h3>
                <p className="question-tag g-color">
                    Decided that youd like to grow with us? Heres how well take
                    it forward.
                </p>
                <br></br>
                <AccordionItem id="lp-one">
                    <AccordionTitle id="lp-one">
                        <span className="question-tag">Q 1: </span>What process
                        does NeoGrowth follow after I apply for a finance loan?
                    </AccordionTitle>

                    <AccordionContent id="lp-one">
                        <p
                            ref={(el) => (textRefs.current[11] = el)}
                            className="f-large"
                        >
                            We assure you of a hassle free experience at
                            NeoGrowth. Our executive will contact and visit you
                            at your convenience to start the loan application
                            process. Once you submit all the requisite
                            documents, we will verify and do a credit check of
                            your business. Post sanction, we will disburse your
                            loan directly into your account within 24- 48 hours.
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="lp-two">
                    <AccordionTitle id="lp-two">
                        <span className="question-tag">Q 2:</span>How much time
                        does it take for NeoGrowth to sanction my loan?
                    </AccordionTitle>
                    <AccordionContent id="lp-two">
                        <p
                            ref={(el) => (textRefs.current[12] = el)}
                            className="f-large"
                        >
                            We are continuously striving to make this as short
                            as possible. Our process starts once you submit all
                            your documents and fill the loan application form.
                            It usually takes us between three to five days for
                            processing and loan is disbursed in 24-48 hours
                            after sanction.
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="lp-three">
                    <AccordionTitle id="lp-three">
                        <span className="question-tag">Q 3:</span>How will the
                        loan be disbursed to me?
                    </AccordionTitle>
                    <AccordionContent id="lp-three">
                        <p
                            ref={(el) => (textRefs.current[13] = el)}
                            className="f-large"
                        >
                            We use two modes for disbursement of loan: NEFT/RTGS
                            , as per your convenience and preference.
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="lp-four">
                    <AccordionTitle id="lp-four">
                        <span className="question-tag">Q 4:</span>How do I repay
                        the loan?
                    </AccordionTitle>
                    <AccordionContent id="lp-four">
                        <p
                            ref={(el) => (textRefs.current[14] = el)}
                            className="f-large"
                        >
                            We have different repayment modes for repayment of
                            loan:
                        </p>
                        <ul
                            ref={(el) => (textRefs.current[15] = el)}
                            className=""
                        >
                            <li>
                                Through POS machine – We automatically deduct
                                the loan EMI{" "}
                            </li>
                            <li>Through ECS / NACH debit mandate</li>
                            <li>
                                Through RTGS/NEFT/IMPS to NeoGrowth designated
                                Account number mentioned in XXXXXXXx
                            </li>
                            <li>
                                Through Bill Desk payment gateway on our Website
                                (http://www.neogrowth.in) as well as through the
                                Customer Portal (http://my.neogrowth.in)
                            </li>
                            <li>
                                Through the Customer APP link
                                (https://play.google.com/store/apps/details?id=com.ng_customer_app
                                ) on the play store you can make the loan
                                payments.
                            </li>
                        </ul>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="lp-five">
                    <AccordionTitle id="lp-five">
                        <span className="question-tag">Q 5:</span>How can I see
                        my loan statement?
                    </AccordionTitle>
                    <AccordionContent id="lp-five">
                        <p
                            ref={(el) => (textRefs.current[16] = el)}
                            className="f-large"
                        >
                            <li>
                                We send the loan statement weekly to your
                                registered email ID. You can also download it
                                from the Customer Portal
                                (http://my.neogrowth.in).
                            </li>
                            <li>
                                You can now download the Customer APP link
                                (https://play.google.com/store/apps/details?id=com.ng_customer_app
                                ) to view all your loan documents like Sanction
                                letter/ Repayment schedule/ Loan
                                Statement/Welcome Letter.
                            </li>
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="lp-six">
                    <AccordionTitle id="lp-six">
                        <span className="question-tag">Q 6:</span>In case of
                        query whom should I contact?
                    </AccordionTitle>
                    <AccordionContent id="lp-six">
                        <p
                            ref={(el) => (textRefs.current[17] = el)}
                            className="f-large"
                        >
                            There are 3 options to reach out to us. You can
                            choose any of the following:
                        </p>
                        <ul
                            ref={(el) => (textRefs.current[18] = el)}
                            className=""
                        >
                            <li>You can email us at helpdesk@neogrowth.in</li>
                            <li>
                                You can raise a service request through the
                                Customer Portal (http://my.neogrowth.in)
                            </li>
                            <li>
                                You can call our customer care at 1800-4195565 &
                                9820655655 (between 10:00 AM to 6:00 PM from
                                Monday to Saturday)
                            </li>
                        </ul>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="lp-seven">
                    <AccordionTitle id="lp-seven">
                        <span className="question-tag">Q 7:</span>Why do we have
                        KFS document now?
                    </AccordionTitle>
                    <AccordionContent id="lp-seven">
                        <p
                            ref={(el) => (textRefs.current[19] = el)}
                            className="f-large"
                        >
                            As per RBI, with the new Digital Lending Guidelines,
                            we have been informed to share a detailed document
                            with all the customers that covers all the Charges
                            as per the calculation given by RBI on the APR and
                            Interest Rate basis the format shared by them.
                        </p>
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
            <br></br>
            <br></br>
            <Accordion classOption="accordion-style2 no-bg">
                <h3 className="title g-color">
                    <strong>
                        <u>Account Aggregator</u>
                    </strong>
                </h3>
                <p className="question-tag g-color"></p>

                <br></br>
                <AccordionItem id="aa-one">
                    <AccordionTitle id="aa-one">
                        <span className="question-tag">Q 1: </span>What is
                        Account aggregator?
                    </AccordionTitle>

                    <AccordionContent id="aa-one">
                        <p
                            ref={(el) => (textRefs.current[20] = el)}
                            className="f-large"
                        >
                            Account Aggregator (AA) is an RBI regulated entity
                            that helps an individual securely and digitally
                            access and share information from one financial
                            institution to another.
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="aa-two">
                    <AccordionTitle id="aa-two">
                        <span className="question-tag">Q 2:</span>What is the
                        benefit of using Account aggregator?
                    </AccordionTitle>
                    <AccordionContent id="aa-two">
                        <p ref={(el) => (textRefs.current[21] = el)}>
                            <p className="f-large">
                                Data will be shared only with the customer’s
                                consent. Customers give and revoke consent as
                                well. Thus, the customer truly becomes the owner
                                of his data.
                            </p>
                            <p className="f-large">
                                The consent framework is very simple, customers
                                will always know
                            </p>
                            <ul className="">
                                <li>With whom is the data being shared?</li>
                                <li>
                                    What is the purpose of sharing the data?
                                </li>
                                <li>
                                    What is the frequency and duration of the
                                    consent?
                                </li>
                            </ul>
                            <p className="f-large">
                                This will reduce the misuse of statement/account
                                data shared in physical form.
                            </p>
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="aa-three">
                    <AccordionTitle id="aa-three">
                        <span className="question-tag">Q 3:</span>How many
                        Account Aggregators does NeoGrowth has?
                    </AccordionTitle>
                    <AccordionContent id="aa-three">
                        <p
                            ref={(el) => (textRefs.current[22] = el)}
                            className="f-large"
                        >
                            NeoGrowth is currently active with Anumati and NADL.
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="aa-four">
                    <AccordionTitle id="aa-four">
                        <span className="question-tag">Q 4:</span>How do we
                        decide between the given AA Entities?
                    </AccordionTitle>
                    <AccordionContent id="aa-four">
                        <p ref={(el) => (textRefs.current[23] = el)}>
                            <p className="f-large">
                                You can select the AA entity based on the bank
                                list given below.
                            </p>
                            <br />
                            <table>
                                <thead>
                                    <tr>
                                        <th
                                            style={{
                                                textAlign: "center",
                                                width: "25%",
                                            }}
                                        >
                                            SR. No.
                                        </th>
                                        <th style={{ width: "25%" }}>
                                            Total Live Banks
                                        </th>
                                        <th
                                            style={{
                                                textAlign: "center",
                                                width: "25%",
                                            }}
                                        >
                                            Anumati
                                        </th>
                                        <th
                                            style={{
                                                textAlign: "center",
                                                width: "25%",
                                            }}
                                        >
                                            NADL
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            1
                                        </td>
                                        <td>Axis Bank</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            2
                                        </td>
                                        <td>Bank of India</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            3
                                        </td>
                                        <td>Bank of Maharashtra</td>
                                        <td style={{ textAlign: "center" }}>
                                            No
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            4
                                        </td>
                                        <td>Central Bank</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            5
                                        </td>
                                        <td>Federal Bank</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            6
                                        </td>
                                        <td>Canara Bank</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            7
                                        </td>
                                        <td>HDFC Bank</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            8
                                        </td>
                                        <td>Indian Bank</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            9
                                        </td>
                                        <td>ICICI Bank</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            10
                                        </td>
                                        <td>IDFC First Bank</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            11
                                        </td>
                                        <td>Indian Overseas Bank</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            12
                                        </td>
                                        <td>Karur Vysya Bank</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            13
                                        </td>
                                        <td>Karnataka Bank</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            14
                                        </td>
                                        <td>Punjab National Bank</td>
                                        <td style={{ textAlign: "center" }}>
                                            No
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            15
                                        </td>
                                        <td>Punjab and Sind Bank</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            16
                                        </td>
                                        <td>State Bank of India</td>
                                        <td style={{ textAlign: "center" }}>
                                            No
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            17
                                        </td>
                                        <td>Union Bank of India</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            18
                                        </td>
                                        <td>UCO Bank</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            19
                                        </td>
                                        <td>AU Bank</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            20
                                        </td>
                                        <td>IndusInd Bank</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            21
                                        </td>
                                        <td>Bank of Baroda</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            22
                                        </td>
                                        <td>Yes Bank</td>
                                        <td style={{ textAlign: "center" }}>
                                            Yes
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            No
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="aa-five">
                    <AccordionTitle id="aa-five">
                        <span className="question-tag">Q 5:</span>What are the
                        different types of consents?
                    </AccordionTitle>
                    <AccordionContent id="aa-five">
                        <p
                            ref={(el) => (textRefs.current[24] = el)}
                            className="f-large"
                        >
                            There are 2 types of consents that NeoGrowth asks
                            its user – One time consent and Recurring consent.
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="aa-six">
                    <AccordionTitle id="aa-six">
                        <span className="question-tag">Q 6:</span>How do I
                        provide one time consent on Account Aggregator?
                    </AccordionTitle>
                    <AccordionContent id="aa-six">
                        <p
                            ref={(el) => (textRefs.current[25] = el)}
                            className="f-large"
                        >
                            NeoGrowth will redirect you to Account Aggregator’s
                            page. Login into the AA page, link the bank accounts
                            and provide the consent. You will be redirected back
                            to NeoGrowth’s page after successful provision of
                            the consent.
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="aa-seven">
                    <AccordionTitle id="aa-seven">
                        <span className="question-tag">Q 7:</span>How do I
                        provide periodic consent on Account Aggregator?
                    </AccordionTitle>
                    <AccordionContent id="aa-seven">
                        <p
                            ref={(el) => (textRefs.current[26] = el)}
                            className="f-large"
                        >
                            NeoGrowth will redirect you to Account Aggregator’s
                            page. Login into the AA page and provide the
                            consent. You will be redirected back to NeoGrowth’s
                            page after successful consent provision. You can
                            also give the consent via the SMS received or
                            through the AA Portal.
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="aa-eight">
                    <AccordionTitle id="aa-eight">
                        <span className="question-tag">Q 8:</span>Is my data
                        safe after providing the consent?
                    </AccordionTitle>
                    <AccordionContent id="aa-eight">
                        <p
                            ref={(el) => (textRefs.current[27] = el)}
                            className="f-large"
                        >
                            NeoGrowth fetches your data from the FIP (Banks)
                            once you have successfully given the consent. This
                            data is used for loan offering and loan monitoring
                            purposes only and this is not shared with any third
                            party.
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="aa-nine">
                    <AccordionTitle id="aa-nine">
                        <span className="question-tag">Q 9:</span>I am unable to
                        discover my bank accounts on AA
                    </AccordionTitle>
                    <AccordionContent id="aa-nine">
                        <p
                            ref={(el) => (textRefs.current[28] = el)}
                            className="f-large"
                        >
                            You can try with different AA entity, or you can
                            choose to skip the AA process after 10 mins of
                            inactivity.
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="aa-ten">
                    <AccordionTitle id="aa-ten">
                        <span className="question-tag">Q 10:</span>Wrong/
                        Inactive bank accounts are displayed in AA
                    </AccordionTitle>
                    <AccordionContent id="aa-ten">
                        <p
                            ref={(el) => (textRefs.current[29] = el)}
                            className="f-large"
                        >
                            You can simply reject the consent by providing
                            appropriate reason.
                        </p>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="aa-eleven">
                    <AccordionTitle id="aa-eleven">
                        <span className="question-tag">Q 11:</span>How can I
                        revoke my consent?
                    </AccordionTitle>
                    <AccordionContent id="aa-eleven">
                        <p
                            ref={(el) => (textRefs.current[30] = el)}
                            className="f-large"
                        >
                            You can login to the concerned Account Aggregator
                            Portal and go to Manage consent page and choose the
                            appropriate required action.
                        </p>
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
        </>
    );
};

export default AccordionWrap;
