import React from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
    EffectFade,
    Autoplay,
    Pagination,
    Navigation,
} from "swiper";
import HomeData from "../../../data/home.json";

const HomePageBanner = ({ image, link }) => {
    SwiperCore.use([Autoplay, Navigation, EffectFade]);
    const swiperOption = {
        loop: true,
        rewind: true,
        // effect: "fade",
        navigation: false,
        speed: 2000,
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: true,
        autoplay: {
            delay: 2000,
            disableOnInteraction: true,
        },
        modules: { EffectFade, Pagination },
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 1,
            },
            // when window width is >= 768px
            768: {
                slidesPerView: 1,
            },
            // when window width is >= 992px
            992: {
                slidesPerView: 1,
            },
        },
    };
    return (
        <div className="home-banner-section position-relative">
            <Swiper className="brand-carousel" {...swiperOption}>
                {HomeData[7].banner &&
                    HomeData[7].banner.map((single, key) => {
                        return (
                            <SwiperSlide key={key}>
                                <a href={link} rel="noreferrer" target="_blank">
                                    <img
                                        className="home-banner-shape item-mobile"
                                        src={single.image}
                                        alt="shape"
                                    />
                                    <img
                                        className="home-banner-shape item-desk"
                                        src={single.imageMob}
                                        alt="shape"
                                    />
                                </a>
                            </SwiperSlide>
                        );
                    })}
            </Swiper>
        </div>
    );
};

HomePageBanner.propTypes = {
    image: PropTypes.string,
    link: PropTypes.string,
};
HomePageBanner.defaultProps = {
    link: "",
};

export default HomePageBanner;
