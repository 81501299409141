import React, { useEffect, useRef, useContext, useState } from "react";
import PropTypes from "prop-types";
import { FontSizeContext } from "../../context/fontSizeContext";

const ServiceDetails = ({ data }) => {
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);
    }, []);

    return (
        <div className="service-wrap">
            {/* <div className="service-thumb bg-light text-center">
                <img src={`${process.env.PUBLIC_URL}/${data.image}`} alt="" />
            </div> */}
            <div className="title-section">
                {/* <h3 className="title pb-2">{data.title}</h3> */}
                <div className="service-content-wrap">
                    <div className="row sub-section-mb mt-7 mt-lg-0">
                        <div className="col-xl-12 col-md-6">
                            <div className="sub-section-title">
                                <p
                                    ref={(el) => (textRefs.current[0] = el)}
                                    className=" b-color"
                                >
                                    <b>Who is it for ?</b>
                                </p>
                                <h5
                                    ref={(el) => (textRefs.current[1] = el)}
                                    className="title g-color px-3"
                                >
                                    <i className="fa fa-angle-right"></i>{" "}
                                    {data.isitfor}
                                </h5>
                            </div>
                            <div className="sub-section-title">
                                <p
                                    ref={(el) => (textRefs.current[2] = el)}
                                    className=" b-color"
                                >
                                    <b>Extra Benefits</b>
                                </p>
                                {data.ExtraBenefits.map((single, key) => {
                                    console.log("this is key", key);
                                    return (
                                        <h5
                                            ref={(el) =>
                                                (textRefs.current[7 + key] = el)
                                            }
                                            className="title g-color px-3"
                                            key={key}
                                        >
                                            <i className="fa fa-angle-right"></i>{" "}
                                            {single}
                                        </h5>
                                    );
                                })}
                            </div>
                            <div className="sub-section-title">
                                <p
                                    ref={(el) => (textRefs.current[4] = el)}
                                    className=" b-color"
                                >
                                    <b>Explanation</b>
                                </p>
                                <h5
                                    ref={(el) => (textRefs.current[5] = el)}
                                    className="title g-color px-3"
                                >
                                    <i className="fa fa-angle-right"></i>{" "}
                                    {data.Explanation}
                                </h5>
                            </div>
                            <div
                                ref={(el) => (textRefs.current[6] = el)}
                                className="sub-section-title"
                            >
                                <p className=" b-color">
                                    <b>Needs to know</b>
                                </p>
                            </div>
                        </div>
                        {/* <div className="col-xl-5 col-md-6 text-center mt-7 mt-md-0 offset-xl-1">
                            <img
                                className="mt-xl-n3 ml-n2"
                                src={data.icon}
                                alt="service"
                            />
                        </div> */}
                    </div>
                    {/* {data.body.map((single, key) => {
                        return (
                            <div
                                className="desc"
                                key={key}
                                dangerouslySetInnerHTML={{ __html: single }}
                            ></div>
                        );
                    })} */}
                </div>
            </div>
        </div>
    );
};

ServiceDetails.propTypes = {
    data: PropTypes.object,
};

export default ServiceDetails;
