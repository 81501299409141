import React from "react";
import SectionTitle from "../../components/section-title";
import PropTypes from "prop-types";

const DownloadAppContainer = ({ contRef, classOption }) => {
    return (
        <div
            className={`service-section position-relative ${classOption}`}
            ref={contRef}
        >
            <div
                className="container p-3"
                style={{
                    borderTop: "solid darkgray",
                    borderBottom: "solid darkgray",
                }}
            >
                <div className="row">
                    <div className="col-xl-8 col-lg-8 mx-auto text-center">
                        <SectionTitle
                            classOption="title-section text-center mb-5"
                            subTitle=""
                            title="<span class='text-primary'>State wise GST details</span>"
                            excerptClassOption="null"
                            excerpt=""
                        />
                    </div>
                </div>

                <div className="row mb-n7 align-items-center">
                    <div className="col-md-12 col-xl-12 mb-9">
                        <table
                            style={{
                                width: "100%",
                                tableLayout: "fixed",
                                wordWrap: "break-word",
                            }}
                        >
                            <tbody>
                                <tr>
                                    <th style={{ textAlign: "center" }}>
                                        <b>Region</b>
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                        <b>State</b>
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                        <b>Branch</b>
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                        <b>GSTIN</b>
                                    </th>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                        rowSpan="8"
                                    >
                                        West Region
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                        rowSpan="4"
                                    >
                                        Gujarat
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        Ahmedabad
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                        rowSpan="4"
                                    >
                                        24AABCB0266D1ZC
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        Baroda
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        Rajkot
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        Surat
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                        rowSpan="4"
                                    >
                                        Maharashtra
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        Mumbai
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                        rowSpan="4"
                                    >
                                        27AABCB0266D1Z6
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        Nagpur
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        Nashik
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        Pune
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                        rowSpan="8"
                                    >
                                        South Region
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                        rowSpan="2"
                                    >
                                        Andhra Pradesh
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        Vijaywada
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                        rowSpan="2"
                                    >
                                        37AABCB0266D1Z5
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        Visakhapatnam
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                        rowSpan="2"
                                    >
                                        Karnataka
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        Bangalore
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                        rowSpan="2"
                                    >
                                        29AABCB0266D1Z2
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        Mysore
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                        rowSpan="3"
                                    >
                                        Tamil Nadu
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        Chennai
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                        rowSpan="3"
                                    >
                                        33AABCB0266D1ZD
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        Coimbatore
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        Madurai
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        Telangana
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        Hyderabad
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        36AABCB0266D1Z7
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                        rowSpan="7"
                                    >
                                        North Region
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        Chandigarh
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        Chandigarh
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        04AABCB0266D1ZE
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        Delhi
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        New Delhi
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        07AABCB0266D1Z8
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        Rajasthan
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        Jaipur
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        08AABCB0266D1Z6
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        Uttar Pradesh
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        Lucknow
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        09AABCB0266D1Z4
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                        rowSpan="2"
                                    >
                                        Punjab
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        Jalandhar
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                        rowSpan="2"
                                    >
                                        03AABCB0266D1ZG
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        Ludhiana
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        Madhya Pradesh
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        Indore
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        23AABCB0266D1ZE
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                        rowSpan="4"
                                    >
                                        East Region
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        West Bengal
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        Kolkata
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        19AABCB0266D1Z3
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        Odisha
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        Bhubaneshwar
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        21AABCB0266D1ZI
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        Jharkhand
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        Jamshedpur
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        20AABCB0266D1ZK
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

DownloadAppContainer.propTypes = {
    classOption: PropTypes.string,
    contRef: PropTypes.string,
};

DownloadAppContainer.defaultProps = {
    classOption: "section-pb",
};

export default DownloadAppContainer;
