import React from "react";
import HomeData from "../../../data/home.json";
import SectionTitle from "../../../components/section-title";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import PropTypes from "prop-types";

const NewsContainer = ({ classOption }) => {
    SwiperCore.use([Autoplay, Navigation]);
    const swiperOption = {
        loop: true,
        rewind: true,
        navigation: false,
        speed: 2000,
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: false,
        autoplay: {
            delay: 500,
            disableOnInteraction: true,
        },
        modules: { Pagination, Navigation },
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 4,
            },
            // when window width is >= 768px
            768: {
                slidesPerView: 4,
            },
            // when window width is >= 992px
            992: {
                slidesPerView: 4,
            },
        },
    };
    return (
        <div className={`brand-section y-background-color  ${classOption}`}>
            <div className="container">
                <div className="row mb-5 ">
                    <div className="col-xl-12 col-lg-12 mx-auto text-center">
                        <SectionTitle
                            classOption="title-section text-center mb-5"
                            subTitle=""
                            title="<span class='text-primary'><a href='news'>NeoGrowth</a></span> in News"
                            excerptClassOption="null"
                            excerpt=""
                        />
                    </div>
                </div>

                <Swiper className="brand-carousel mb-n7" {...swiperOption}>
                    {HomeData[1].news &&
                        HomeData[1].news.map((single, key) => {
                            return (
                                <SwiperSlide key={key}>
                                    <a
                                        href={single.link}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <img
                                            className="home-banner-shape"
                                            src={single.image}
                                            alt="shape"
                                        />
                                    </a>
                                </SwiperSlide>
                            );
                        })}
                </Swiper>
            </div>
        </div>
    );
};

NewsContainer.propTypes = {
    classOption: PropTypes.string,
};

NewsContainer.defaultProps = {
    classOption: "mt-10 mt-lg-0",
};

export default NewsContainer;
