import { useEffect, useRef, useState, useContext } from "react";
import SectionTitle from "../../../components/section-title";
import { Swiper, SwiperSlide } from "swiper/react";
import Testimonial from "../../../components/testimonial";
import TestimonialData from "../../../data/testimonial.json";
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from "swiper";
import Parallax from "parallax-js";
import PropTypes from "prop-types";
import Button from "../../../components/button";
import { FontSizeContext } from "../../../context/fontSizeContext";

SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);

const TestimonialContainer = ({ classOption }) => {
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);

    const swiperOption = {
        loop: false,
        speed: 1000,
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
            nextEl: ".testimonial-carousel .swiper-button-next",
            prevEl: ".testimonial-carousel .swiper-button-prev",
        },
    };
    const sceneEl = useRef(null);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);

        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    return (
        <div className={`testimonial-section position-relative ${classOption}`}>
            {/* <img
                className="tetimonial-shape"
                src={`${process.env.PUBLIC_URL}/images/testimonial/shape.png`}
                alt=""
            /> */}
            <div className="row mb-5 me-0">
                <div className="col-xl-12 col-lg-12 mx-auto text-center">
                    <SectionTitle
                        classOption="title-section text-center mb-5"
                        subTitle=""
                        title="Our <span class='text-primary'><a href='/'>Happy customers!</a></span> "
                        excerptClassOption="null"
                        excerpt=""
                    />
                </div>
            </div>
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-5 col-lg-6 mb-7 align-self-center text-center">
                        <div
                            className="testimonial-photo scene mt-5 mb-10 mt-lg-0"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/banner/happyCust.png`}
                                    alt="NeoGrowth"
                                />
                            </div>
                        </div>
                        {/* <Button
                            classOption="btn btn-xm btn-primary btn-hover-primary"
                            text="Client Stories"
                            path="/"
                        /> */}
                    </div>
                    <div className="col-xl-7 col-lg-6 mb-7">
                        <div className="testimonial-carousel position-relative">
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={0}
                                loop={true}
                                speed={2000}
                                pagination={{
                                    clickable: true,
                                }}
                                autoplay={{
                                    delay: 2000,
                                    disableOnInteraction: false,
                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                            >
                                {TestimonialData &&
                                    TestimonialData.map((single, key) => {
                                        return (
                                            <SwiperSlide key={key}>
                                                <Testimonial
                                                    key={key}
                                                    data={single}
                                                />
                                            </SwiperSlide>
                                        );
                                    })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

TestimonialContainer.propTypes = {
    classOption: PropTypes.string,
};

TestimonialContainer.defaultProps = {
    classOption: "section-pb",
};

export default TestimonialContainer;
