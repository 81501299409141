import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

const LoanForm = () => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isValid },
    } = useForm({
        mode: "onChange", // Validates onChange
    });

    const [digitalPay, setDigitalPay] = useState(false);

    const handleRadioChange = (event) => {
        setDigitalPay(event.target.id === "customRadioInline1");
    };

    const onSubmit = (data) => {
        console.log("Form Submitted:", data);
        alert("Form submitted successfully!");
    };

    return (
        <Fragment>
            <form
                id="contactForm"
                className="row"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className={`form-control ${
                            errors.name ? "is-invalid" : ""
                        }`}
                        placeholder="Your Full Name*"
                        {...register("name", {
                            required: "Name is required",
                            minLength: {
                                value: 3,
                                message:
                                    "Name must be at least 3 characters long",
                            },
                        })}
                    />
                    {errors.name && (
                        <p className="text-danger">{errors.name.message}</p>
                    )}
                </div>

                <div className="col-12 col-sm-6 mb-7">
                    <select
                        className={`form-control ${
                            errors.city ? "is-invalid" : ""
                        }`}
                        {...register("city", {
                            required: "City selection is required",
                        })}
                    >
                        <option value="">Select Your City</option>
                        <option value="ahmedabad">Ahmedabad</option>
                        <option value="bangalore">Bangalore</option>
                        <option value="baroda">Baroda</option>
                        <option value="bhubaneshwar">Bhubaneswar</option>
                        <option value="chandigarh">Chandigarh</option>
                        <option value="chennai">Chennai</option>
                        <option value="coimbatore">Coimbatore</option>
                        <option value="delhi">Delhi</option>
                        <option value="hyderabad">Hyderabad</option>
                        <option value="indore">Indore</option>
                        <option value="jaipur">Jaipur</option>
                        <option value="jalandhar">Jalandhar</option>
                        <option value="jamshedpur">Jamshedpur</option>
                        <option value="kolkata">Kolkata</option>
                        <option value="lucknow">Lucknow</option>
                        <option value="ludhiana">Ludhiana</option>
                        <option value="madurai">Madurai</option>
                        <option value="mumbai">Mumbai</option>
                        <option value="mysore">Mysore</option>
                        <option value="nagpur">Nagpur</option>
                        <option value="nashik">Nashik</option>
                        <option value="pune">Pune</option>
                        <option value="rajkot">Rajkot</option>
                        <option value="ranchi">Ranchi</option>
                        <option value="surat">Surat</option>
                        <option value="vijaywada">Vijaywada</option>
                        <option value="vizag">Visakhapatnam</option>
                    </select>
                    {errors.city && (
                        <span className="text-danger">
                            {errors.city.message}
                        </span>
                    )}
                </div>

                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className={`form-control ${
                            errors.phone ? "is-invalid" : ""
                        }`}
                        placeholder="Your Phone Number*"
                        {...register("phone", {
                            required: "Phone number is required",
                            pattern: {
                                value: /^[0-9]{10}$/,
                                message: "Phone number must be 10 digits",
                            },
                        })}
                    />
                    {errors.phone && (
                        <span className="text-danger">
                            {errors.phone.message}
                        </span>
                    )}
                </div>

                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="email"
                        className={`form-control ${
                            errors.email ? "is-invalid" : ""
                        }`}
                        placeholder="Your Email*"
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address",
                            },
                        })}
                    />
                    {errors.email && (
                        <span className="text-danger">
                            {errors.email.message}
                        </span>
                    )}
                </div>

                <div className="col-12 col-sm-6 mb-7">
                    <select
                        className={`form-control ${
                            errors.businessExperience ? "is-invalid" : ""
                        }`}
                        {...register("businessExperience", {
                            required: "This field is required",
                        })}
                    >
                        <option value="">Business Experience*</option>
                        <option value="Less than 2 years">
                            Less than 2 years
                        </option>
                        <option value="2-5 years">2-5 years</option>
                        <option value="5 years &amp; above">
                            5 years &amp; above
                        </option>
                    </select>
                    {errors.businessExperience && (
                        <span className="text-danger">
                            {errors.businessExperience.message}
                        </span>
                    )}
                </div>

                <div className="col-12 col-sm-6 mb-7">
                    <select
                        className={`form-control ${
                            errors.avgMonthlySale ? "is-invalid" : ""
                        }`}
                        {...register("avgMonthlySale", {
                            required: "This field is required",
                        })}
                    >
                        <option value="">Your Average Monthly Sale*</option>
                        <option value="Less than 1 lakh">
                            Less than 1 lakh
                        </option>
                        <option value="Between 1 to 3 lakhs">
                            Between 1 to 3 lakhs
                        </option>
                        <option value="Between 3 to 5 lakhs">
                            Between 3 to 5 lakhs
                        </option>
                        <option value="More than 5 lakhs">
                            More than 5 lakhs
                        </option>
                    </select>
                    {errors.avgMonthlySale && (
                        <span className="text-danger">
                            {errors.avgMonthlySale.message}
                        </span>
                    )}
                </div>

                <div className="col-12 col-sm-12 mb-7">
                    <select
                        className={`form-control ${
                            errors.businessIndustry ? "is-invalid" : ""
                        }`}
                        {...register("businessIndustry", {
                            required: "This field is required",
                        })}
                    >
                        <option value="">Your Business Industry*</option>
                        <option value="General_Store_Supermarket">
                            General Store / Supermarket
                        </option>
                        <option value="Doctor">Doctor</option>
                        <option value="Hospitals">Hospitals</option>
                        <option value="Hardware_and_Electrical">
                            Hardware and Electrical
                        </option>
                        <option value="Contractor">Contractor</option>
                        <option value="FMCG">FMCG</option>
                        <option value="Packaging_Material">
                            Packaging Material
                        </option>
                        <option value="Plastics">Plastics</option>
                        <option value="Fuel_and_Lubricants">
                            Fuel and Lubricants
                        </option>
                        <option value="Pharmaceuticals">Pharmaceuticals</option>
                        <option value="HealthCare">HealthCare</option>
                        <option value="IT_and_ITeS">IT and ITeS</option>
                        <option value="Facility_Management">
                            Facility Management
                        </option>
                        <option value="Skilled_or_Semi_Skilled_Labour_Supplier">
                            Skilled or Semi-Skilled Labour Supplier
                        </option>
                        <option value="grocery">Groceries</option>
                        <option value="petrol pump">Petrol Pump</option>
                        <option value="Pharmacy">Pharmacy</option>
                        <option value="clinic">Clinic &amp; Lab</option>
                        <option value="Optician">Optician</option>
                        <option value="cdit">CDIT</option>
                        <option value="home appliances">Home Appliances</option>
                        <option value="Consumer_Durables">
                            Consumer Durables
                        </option>
                        <option value="Others">Others</option>
                    </select>
                    {errors.businessIndustry && (
                        <span className="text-danger">
                            {errors.businessIndustry.message}
                        </span>
                    )}
                </div>

                <div className="row col-12 col-sm-12 mb-7">
                    <p>
                        Do you accept digital payment (Credit
                        Card/UPI/IMPS/Wallets)?*
                    </p>
                    <div className="col-md-6">
                        <input
                            type="radio"
                            id="customRadioInline1"
                            {...register("digitalPayment", {
                                required: "Please select an option",
                            })}
                            className="custom-control-input "
                            style={{
                                transform: "scale(1.4)",
                                accentColor: "green",
                            }}
                            onChange={handleRadioChange}
                        />
                        <label htmlFor="customRadioInline1" className="ps-2">
                            Yes
                        </label>
                    </div>
                    <div className="col-md-6 ">
                        <input
                            type="radio"
                            id="customRadioInline2"
                            {...register("digitalPayment", {
                                required: "Please select an option",
                            })}
                            className="custom-control-input"
                            style={{
                                transform: "scale(1.4)",
                                accentColor: "green",
                            }}
                            onChange={handleRadioChange}
                        />
                        <label htmlFor="customRadioInline2" className="ps-2">
                            No
                        </label>
                    </div>
                    {errors.digitalPayment && (
                        <span className="text-danger">
                            {errors.digitalPayment.message}
                        </span>
                    )}
                </div>

                {digitalPay && (
                    <>
                        <div className="col-12 col-sm-6 mb-7">
                            <select
                                className={`form-control ${
                                    errors.monthlyCard ? "is-invalid" : ""
                                }`}
                                {...register("monthlyCard", {
                                    required: "This field is required",
                                })}
                            >
                                <option value="">
                                    Monthly Card & Digital Sales*
                                </option>
                                <option value="Less than one lakh">
                                    Less than one lakh
                                </option>
                                <option value="1-2 Lakhs">1-2 Lakhs</option>
                                <option value="More than 2 Lakhs">
                                    More than 2 Lakhs
                                </option>
                            </select>
                            {errors.monthlyCard && (
                                <span className="text-danger">
                                    {errors.monthlyCard.message}
                                </span>
                            )}
                        </div>

                        <div className="col-12 col-sm-6 mb-7">
                            <select
                                className={`form-control ${
                                    errors.digitalSales ? "is-invalid" : ""
                                }`}
                                {...register("digitalSales", {
                                    required: "This field is required",
                                })}
                            >
                                <option value="">
                                    Accepting payment through Debit/Credit card
                                    since*
                                </option>
                                <option value="Less than 6 months">
                                    Less than 6 months
                                </option>
                                <option value="6 months and more">
                                    6 months and more
                                </option>
                            </select>
                            {errors.digitalSales && (
                                <span className="text-danger">
                                    {errors.digitalSales.message}
                                </span>
                            )}
                        </div>
                    </>
                )}

                <div className="col-auto">
                    <div className="form-check mb-2">
                        <input
                            className={`form-check-input ${
                                errors.terms ? "is-invalid" : ""
                            }`}
                            type="checkbox"
                            id="autoSizingCheck"
                            {...register("terms", {
                                required:
                                    "You must agree to the terms and conditions",
                            })}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="autoSizingCheck"
                        >
                            I agree to NeoGrowth’ s Privacy Policy and T&C. I
                            hereby give my consent to NeoGrowth to call on above
                            mentioned number and receive my Credit Information
                            from any Credit Bureau for the purpose of loan
                            underwriting and account monitoring.
                        </label>
                        {errors.terms && (
                            <span className="text-danger">
                                {errors.terms.message}
                            </span>
                        )}
                    </div>
                </div>

                <div className="col-12 col-sm-12 mb-7">
                    <ReCAPTCHA
                        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                        // onChange={onChange}
                    />
                </div>

                <div className="col-12 text-center">
                    <button
                        type="submit"
                        className={`btn btn-dark btn-hover-dark ${
                            !isValid ? "disabled" : ""
                        }`}
                    >
                        Submit
                    </button>
                </div>
            </form>
        </Fragment>
    );
};

export default LoanForm;
