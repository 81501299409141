import { useEffect, useRef, useState, useContext } from "react";
import Button from "../../components/button";
import SectionTitle from "../../components/section-title";
import Parallax from "parallax-js";
import PropTypes from "prop-types";
import { FontSizeContext } from "../../context/fontSizeContext";

const HomeAboutContainer = ({ contRef, classOption }) => {
    const sceneEl = useRef(null);
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    return (
        <div
            className={`about-us position-relative ${classOption}`}
            ref={contRef}
        >
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-9 col-lg-9 mb-7">
                        <div className="about-content">
                            <span
                                ref={(el) => (textRefs.current[0] = el)}
                                className="date-meta g-color"
                            >
                                <b>
                                    How NeoGrowth is Ushering Positive Impact{" "}
                                </b>
                                <span className="hr"></span>
                            </span>
                            <p
                                ref={(el) => (textRefs.current[1] = el)}
                                className="text-dark mb-5"
                            >
                                At NeoGrowth, we embrace an integrated impact
                                approach that seamlessly combines
                                sustainability, inclusivity, and responsible
                                business growth. Our strategy focuses on
                                creating a positive and lasting impact across
                                all our operations, ensuring that our credit
                                solutions not only support the growth of
                                India&lsquo;s MSMEs but also contribute to
                                broader societal and environmental goals.
                            </p>
                            <p
                                ref={(el) => (textRefs.current[1] = el)}
                                className="text-dark mb-5"
                            >
                                Aligned with six United Nations Sustainable
                                Development Goals (SDGs), we have set measurable
                                targets to track our impact and drive meaningful
                                change. Our NeoGrowth Impact Report showcases
                                our efforts in understanding customer needs,
                                addressing financing challenges, and assessing
                                our influence across various parameters. It also
                                reflects our unwavering commitment to governance
                                and environmental sustainability.
                            </p>
                            <p
                                ref={(el) => (textRefs.current[1] = el)}
                                className="text-dark mb-5"
                            >
                                As we celebrate impactful lending, NeoGrowth
                                remains dedicated to fostering positive change,
                                promoting inclusive growth, and advancing
                                sustainable development in every aspect of our
                                operations.
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 mb-7 align-self-center">
                        <div className="text-center">
                            <div>
                                <img
                                    className="impact-image"
                                    src={`${process.env.PUBLIC_URL}/images/impact-report/ImpactReportCoverPage.jpg`}
                                    alt="about"
                                />
                            </div>
                            <div className="pt-5">
                                <Button
                                    classOption="btn btn-lg btn-primary btn-hover-primary"
                                    text="Download Report"
                                    link={
                                        process.env.PUBLIC_URL +
                                        "/pdf/impact/Social_Impact_Report_2024.pdf"
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

HomeAboutContainer.propTypes = {
    classOption: PropTypes.string,
    contRef: PropTypes.string,
};

HomeAboutContainer.defaultProps = {
    classOption: "section-pb",
};

export default HomeAboutContainer;
