import React from "react";
import SectionTitle from "../../../components/section-title";
import Button from "../../../components/button";
import ServiceBox from "../../../components/service-box/box-left";
import ServiceBoxRight from "../../../components/service-box/box-right";
import ServiceData from "../../../data/service.json";
import HomeData from "../../../data/home.json";
import PropTypes from "prop-types";

const ServiceListContainer = ({ classOption }) => {
    return (
        <div className={`service-section position-relative ${classOption}`}>
            <div className="container">
                <div className="row mb-5 ">
                    <div className="col-xl-8 col-lg-8 mx-auto text-center">
                        <SectionTitle
                            classOption="title-section text-center mb-5"
                            subTitle=""
                            title="Business <span class='text-primary'><a href='products'>Loans</a></span>"
                            excerptClassOption="null"
                            excerpt="For retailers, manufacturers, traders, suppliers, service providers, dealers & distributors"
                        />
                    </div>
                </div>

                <div className="row mb-n7 align-items-center">
                    <div className="col-md-6 col-xl-4 mb-7">
                        <div className="service-media-wrapper media-spacing-left">
                            {HomeData[9].product &&
                                HomeData[9].product
                                    .slice(0, 3)
                                    .map((single, key) => {
                                        // {ServiceData &&
                                        //     ServiceData.slice(0, 3).map((single, key) => {
                                        if (single.id) {
                                            return (
                                                <ServiceBox
                                                    data={single}
                                                    key={key}
                                                    classOption={
                                                        "product-box-left-shadow"
                                                    }
                                                />
                                            );
                                        }
                                    })}
                        </div>
                    </div>

                    <div className="col-xl-4 mb-7 order-md-1 order-xl-0">
                        <div className="service-media-img text-center">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/banner/BusinessLoan.png`}
                                alt="Products"
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-4 mb-7">
                        <div className="service-media-wrapper media-spacing-right">
                            {HomeData[9].product &&
                                HomeData[9].product
                                    .slice(3, 6)
                                    .map((single, key) => {
                                        if (single.id) {
                                            return (
                                                <ServiceBoxRight
                                                    data={single}
                                                    key={key}
                                                    classOption={
                                                        "product-box-right-shadow"
                                                    }
                                                />
                                            );
                                        }
                                    })}
                        </div>
                    </div>
                </div>
                <div className="row mb-5 mt-5">
                    <div className="col-xl-8 col-lg-8 mx-auto text-center">
                        <Button
                            classOption="btn btn-xm btn-primary btn-hover-primary"
                            text="Product Details"
                            link="https://ng.neogrowth.in/products/"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

ServiceListContainer.propTypes = {
    classOption: PropTypes.string,
};

ServiceListContainer.defaultProps = {
    classOption: "section-pb",
};

export default ServiceListContainer;
