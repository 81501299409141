import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

const HelpForm = () => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isValid },
    } = useForm({
        mode: "onChange", // Validates onChange
    });

    const [existingCustomer, setExistingCustomer] = useState(true);

    const handleRadioChange = (event) => {
        setExistingCustomer(event.target.id === "customRadioInline1");
    };

    const onSubmit = (data) => {
        console.log("Form Submitted:", data);
        alert("Form submitted successfully!");
    };

    return (
        <Fragment>
            <form
                id="contactForm"
                className="row"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className={`form-control ${
                            errors.name ? "is-invalid" : ""
                        }`}
                        placeholder="Your Full Name*"
                        {...register("name", {
                            required: "Name is required",
                            minLength: {
                                value: 3,
                                message:
                                    "Name must be at least 3 characters long",
                            },
                        })}
                    />
                    {errors.name && (
                        <p className="text-danger">{errors.name.message}</p>
                    )}
                </div>

                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className={`form-control ${
                            errors.phone ? "is-invalid" : ""
                        }`}
                        placeholder="Your Phone Number*"
                        {...register("phone", {
                            required: "Phone number is required",
                            pattern: {
                                value: /^[0-9]{10}$/,
                                message: "Phone number must be 10 digits",
                            },
                        })}
                    />
                    {errors.phone && (
                        <span className="text-danger">
                            {errors.phone.message}
                        </span>
                    )}
                </div>

                <div className="col-12 col-sm-12 mb-7">
                    <input
                        type="email"
                        className={`form-control ${
                            errors.email ? "is-invalid" : ""
                        }`}
                        placeholder="Your Email*"
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address",
                            },
                        })}
                    />
                    {errors.email && (
                        <span className="text-danger">
                            {errors.email.message}
                        </span>
                    )}
                </div>

                <div className="row col-12 col-sm-12 mb-7">
                    <p>Are you an existing Customer?</p>
                    <div className="col-md-6">
                        <input
                            type="radio"
                            id="customRadioInline1"
                            {...register("digitalPayment", {
                                required: "Please select an option",
                            })}
                            className="custom-control-input "
                            style={{
                                transform: "scale(1.4)",
                                accentColor: "green",
                            }}
                            onChange={handleRadioChange}
                        />
                        <label htmlFor="customRadioInline1" className="ps-2">
                            Yes
                        </label>
                    </div>
                    <div className="col-md-6 ">
                        <input
                            type="radio"
                            id="customRadioInline2"
                            {...register("digitalPayment", {
                                required: "Please select an option",
                            })}
                            className="custom-control-input"
                            style={{
                                transform: "scale(1.4)",
                                accentColor: "green",
                            }}
                            onChange={handleRadioChange}
                        />
                        <label htmlFor="customRadioInline2" className="ps-2">
                            No
                        </label>
                    </div>
                    {errors.digitalPayment && (
                        <span className="text-danger">
                            {errors.digitalPayment.message}
                        </span>
                    )}
                </div>

                {existingCustomer ? (
                    <div className="col-12 col-sm-12 mb-7">
                        <input
                            type="text"
                            className={`form-control ${
                                errors.applicaionId ? "is-invalid" : ""
                            }`}
                            placeholder="Application Id*"
                            {...register("applicaionId", {
                                required: "Phone number is required",
                                pattern: {
                                    value: /^[0-9]{10}$/,
                                    message: "Phone number must be 10 digits",
                                },
                            })}
                        />
                        {errors.applicaionId && (
                            <span className="text-danger">
                                {errors.applicaionId.message}
                            </span>
                        )}
                    </div>
                ) : (
                    <div className="col-12 mb-9">
                        <textarea
                            className="form-control massage-control"
                            name="message"
                            id="massage"
                            cols="10"
                            rows="3"
                            placeholder="Additional Details"
                            {...register("additionDetails", {
                                required: "Message is required",
                            })}
                        ></textarea>
                        {errors.additionDetails && (
                            <p>{errors.additionDetails.message}</p>
                        )}
                    </div>
                )}

                <div className="col-12 mb-9">
                    <textarea
                        className="form-control massage-control"
                        name="message"
                        id="massage"
                        cols="10"
                        rows="3"
                        placeholder="Message"
                        {...register("message", {
                            required: "Message is required",
                        })}
                    ></textarea>
                    {errors.message && <p>{errors.message.message}</p>}
                </div>

                <div className="col-12 col-sm-12 mb-7">
                    <ReCAPTCHA
                        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                        // onChange={onChange}
                    />
                </div>

                <div className="col-12">
                    <button
                        type="submit"
                        className={`btn btn-dark btn-hover-dark ${
                            !isValid ? "disabled" : ""
                        }`}
                    >
                        Submit
                    </button>
                </div>
            </form>
        </Fragment>
    );
};

export default HelpForm;
