import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/button";
import InfoBox from "../../../components/info-box";
import SectionTitle from "../../../components/section-title";
import HomeData from "../../../data/home.json";
import BlogGrid from "./details";

const InfoBoxContainer = ({ classOption }) => {
    return (
        <div
            className={`feature-section position-relative y-background-color ${classOption}`}
        >
            {/* <img
                    className="path-img"
                    src={`${process.env.PUBLIC_URL}/images/feature/shape.png`}
                    alt="images_not_found"
                /> */}
            <div className="container custom-container">
                <div className="row g-0 align-items-center">
                    <div className="col-xl-6 col-lg-8 mx-auto mb-3 text-center">
                        <SectionTitle
                            classOption="title-section"
                            subTitle=""
                            title="Why Choose <span class='text-primary'> NeoGrowth?</span>"
                            excerptClassOption="null"
                            excerpt=""
                        />
                    </div>

                    <div className="row col-xl-12 col-lg-12 mx-auto mb-3 text-center">
                        {/* <div id="grid" className="grid row mb-n7">
                            {HomeData[2].iconBox &&
                                HomeData[2].iconBox.map((single, key) => {
                                    return <InfoBox key={key} data={single} />;
                                })}
                        </div> */}
                        {HomeData[2].iconBox &&
                            HomeData[2].iconBox.map((single, key) => {
                                return (
                                    <div
                                        key={key}
                                        className="col-lg-3 col-sm-4 mb-7"
                                    >
                                        <BlogGrid
                                            classOption="null"
                                            key={key}
                                            data={single}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};

InfoBoxContainer.propTypes = {
    classOption: PropTypes.string,
};

InfoBoxContainer.defaultProps = {
    classOption: "section-pb",
};

export default InfoBoxContainer;
