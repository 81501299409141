import { useEffect, useRef, useContext, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { slugify } from "../../utils";
import Button from "../../components/button";
import { FontSizeContext } from "../../context/fontSizeContext";

const Testimonial = ({ data }) => {
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);
    }, []);

    return (
        <div className="testimonial-slide-item ">
            <span className="quote zmdi zmdi-quote"></span>
            <p
                ref={(el) => (textRefs.current[0] = el)}
                className="testimonial-text mt-2 mb-5"
            >
                {data.excerpt}
            </p>
            <div className="avater d-flex">
                <div className="avater-profile">
                    <Link
                        to={
                            process.env.PUBLIC_URL +
                            `/clientstories/${slugify(data.id)}`
                        }
                    >
                        <img
                            src={process.env.PUBLIC_URL + data.authorThumb}
                            alt="avater"
                        />
                    </Link>
                </div>
                <div className="avater-info">
                    <p
                        className="text-dark"
                        ref={(el) => (textRefs.current[1] = el)}
                    >
                        <Link
                            to={
                                process.env.PUBLIC_URL +
                                `/clientstories/${slugify(data.id)}`
                            }
                        >
                            {data.name}
                        </Link>
                    </p>
                    <span ref={(el) => (textRefs.current[2] = el)}>
                        <i className="fa fa-map-marker">&nbsp;</i>
                        {data.location}
                    </span>
                </div>
            </div>
            <div className="mt-5 mb-5">
                <Button
                    classOption="btn btn-xm btn-primary btn-hover-primary mt-0"
                    text="Read more"
                    link={data.link}
                />
            </div>
        </div>
    );
};

Testimonial.propTypes = {
    data: PropTypes.object,
};

export default Testimonial;
