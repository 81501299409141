import React, { useRef } from "react";
import NewsletterArea from "../../containers/global/newsletter";
import PageBanner from "../../containers/global/page-banner";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";
import Layout from "../../layouts/index";
import StockExchangeIntimationData from "../../data/stock-exchange-intimation.json";
import StockExchangeIntimationContainer from "../../containers/investor-relations/stock-exchange-intimation";
import ScrollToTop from "../../components/scroll-to-top";
import SEO from "../../components/seo";

const StockExchangeIntimationPage = () => {
    const contRef = useRef(null);
    const handleScrollToHeader = () => {
        console.log("scrllheader", contRef);
        if (contRef.current) {
            console.log("scrllheader2");
            // Adjust scrolling to account for the height of the small div
            const headerPosition = contRef.current.offsetTop;
            const divHeight = 0; // Adjust this if your div's height changes
            window.scrollTo({
                top: headerPosition,
                behavior: "smooth",
            });
        }
    };
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Stock Exchange Intimation | NeoGrowth - Lending simplified. Growth amplified" />
                <div className="wrapper">
                    <Header
                        onPressSkipButton={() => {
                            handleScrollToHeader();
                        }}
                    />
                    <PageBanner
                        title="Stock Exchange Intimation"
                        excerpt=""
                        image="/images/banner/page/stock-exchange.png"
                    />
                    <StockExchangeIntimationContainer
                        contRef={contRef}
                        data={StockExchangeIntimationData}
                    />

                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default StockExchangeIntimationPage;
