import React from "react";
import BlogData from "../../../data/blog.json";
import SectionTitle from "../../../components/section-title";
import Button from "../../../components/button";
import BlogGrid from "../../../components/blog";
import PropTypes from "prop-types";

const HomeBlog = ({ classOption }) => {
    return (
        <div className={`blog-section ${classOption}`}>
            <div className="container">
                <div className="row mb-5 ">
                    <div className="col-xl-8 col-lg-8 mx-auto text-center">
                        <SectionTitle
                            classOption="title-section text-center mb-5"
                            subTitle=""
                            title="Read from <span class='text-primary'>NeoBlog</span>"
                            excerptClassOption="null"
                            excerpt="Ideas for Growth"
                        />
                        {/* <Button
                            classOption="btn btn-xm btn-primary btn-hover-primary"
                            text="Products"
                            path="/loan"
                        /> */}
                    </div>
                </div>

                <div className="row mb-n7">
                    {BlogData &&
                        BlogData.slice(0, 3).map((single, key) => {
                            return (
                                <div
                                    key={key}
                                    className="col-lg-4 col-sm-6 mb-7"
                                >
                                    <BlogGrid
                                        classOption="null"
                                        key={key}
                                        data={single}
                                    />
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

HomeBlog.propTypes = {
    classOption: PropTypes.string,
};

HomeBlog.defaultProps = {
    classOption: "mt-10 mt-lg-0",
};

export default HomeBlog;
