import React, { useEffect, useRef, useContext, useState } from "react";
import PropTypes from "prop-types";
import { FontSizeContext } from "../../context/fontSizeContext";
import SectionTitle from "../../components/section-title";

const ARContainer = ({ data, contRef }) => {
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);
    }, []);
    return (
        <div className="single-service pb-0 pt-10 " ref={contRef}>
            <div className="container">
                <div className="row justify-content-center m-1">
                    {data.map((single, i) => {
                        return (
                            <div
                                className="col-xl-8 col-lg-6 col-sm-6 mb-5 awards-box"
                                key={i}
                            >
                                <div className="col-md-4 p-3 text-center">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            single.thumb
                                        }
                                        style={{
                                            maxHeight: "100px",
                                        }}
                                        alt="about"
                                    />
                                </div>
                                <div className="col-md-8 p-3">
                                    <p
                                        className="b-color justify-content-center"
                                        ref={(el) => (textRefs.current[i] = el)}
                                    >
                                        {single.details}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

ARContainer.propTypes = {
    data: PropTypes.array,
    contRef: PropTypes.any,
};

export default ARContainer;
