import React, { useEffect, useRef, useContext, useState } from "react";
import SectionTitle from "../../components/section-title";
import Button from "../../components/button";
import ServiceBox from "../../components/service-box";
import ServiceData from "../../data/service.json";
import PropTypes from "prop-types";
import { FontSizeContext } from "../../context/fontSizeContext";

const DownloadAppContainer = ({ contRef, classOption }) => {
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);
    }, []);

    return (
        <div
            className={`service-section position-relative ${classOption}`}
            ref={contRef}
        >
            <div
                className="container p-3"
                style={{
                    borderTop: "solid darkgray",
                    borderBottom: "solid darkgray",
                }}
            >
                <div className="row">
                    <div className="col-xl-8 col-lg-8 mx-auto text-center">
                        <SectionTitle
                            classOption="title-section text-center mb-5"
                            subTitle=""
                            title="<span class='text-primary'>Disclaimer</span>"
                            excerptClassOption="null"
                            excerpt=""
                        />
                    </div>
                </div>

                <div
                    ref={(el) => (textRefs.current[0] = el)}
                    className="row mb-n7 align-items-center"
                >
                    <div className="col-md-12 col-xl-12 mb-9">
                        <p>
                            This website is the online portal for NeoGrowth
                            Credit Pvt. Ltd. and is to be used for personal
                            information purposes only. All the information
                            displayed, transmitted or carried by the website
                            including, but not limited to guides, news articles,
                            external links, opinions, text, photographs, images,
                            illustrations, trademarks, service marks and the
                            like are provided on an &quot;as is&quot; basis
                            without warranties of any kind and stand protected
                            by the copyright and other intellectual property
                            laws. Any of the content published on the website
                            shall not be reproduced, distributed, transmitted,
                            modified, reused or published in whole or in part by
                            the recipient hereof or any other person for any
                            purpose without the prior written approval of the
                            company.
                        </p>
                        <p>
                            While the content of the website may be updated
                            periodically, we do not guarantee that it reflects
                            the latest amendments/ information at any time.
                        </p>
                        <p>
                            All text, data, graphs and other pieces of
                            information are presented with the best possible
                            attempts to maintaining integrity, consistency and
                            reliability of the same. However, none of the
                            employees, directors, consultants, agents,
                            representatives stand guarantors to any kind about
                            the completeness, accuracy, reliability, suitability
                            or availability with respect to the website or the
                            information, products, services, or related graphics
                            contained on the website for any purpose. Any
                            reliance you place on such information is therefore
                            strictly at your own risk. In the event that an
                            inaccuracy or discrepancy is noticed by anyone who
                            accesses the website, we would like you to inform us
                            so that it can be corrected.
                        </p>
                        <p>
                            None of the company representative stands liable for
                            any direct or indirect loss of profit or
                            consequential damages that are alleged to have
                            resulted from the use and/or inability to access or
                            use the website features or misinterpretation or
                            misrepresentation of information of any kind. The
                            company does not become liable for any technical
                            failure or malfunctioning of the software or the
                            performance of any of our services. We are also not
                            responsible for non-receipt of registration details
                            or e-mails. Users shall bear all responsibility of
                            keeping the password secure and we are not
                            responsible for the loss or misuse of the same.
                        </p>
                        <p>
                            This website provides some link to other websites
                            which are not under our direct control. The
                            inclusion of any links does not necessarily imply a
                            recommendation or endorse the views expressed within
                            them and they are presented without any prior
                            screening or review. While attempts are made at
                            delivering only the relevant information to our
                            users, no representative of the company holds any
                            kind of liability as to the use of such content made
                            available through those sites.
                        </p>
                        <p>
                            We strive at keeping the website up and running
                            smoothly along with all its features and services.
                            However, technical issues beyond our control may
                            arise when the website becomes temporarily
                            unavailable or some of the features may not work as
                            they are expected to. The company does not take
                            responsibility for such events and will not be
                            liable for any financial or non-tangible losses
                            arising due to the same. We do not warrant that the
                            use of services, software or any other features
                            available on the website will be uninterrupted,
                            secure or error free or that any such defects in the
                            services will be corrected.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

DownloadAppContainer.propTypes = {
    classOption: PropTypes.string,
    contRef: PropTypes.string,
};

DownloadAppContainer.defaultProps = {
    classOption: "section-pb",
};

export default DownloadAppContainer;
