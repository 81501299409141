import React, { useRef, useState } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import BrandContainer from "../containers/global/brand/index";
import NewsContainer from "../containers/home/news";
import FunFactContainer from "../containers/global/funfact";
import ImpactContainer from "../containers/home/impact";
import IconBoxContainer from "../containers/global/icon-box";
import InfoBoxContainer from "../containers/home/info-box";

import NewsletterArea from "../containers/global/newsletter";
import TeamContainer from "../containers/global/team";
import TestimonialContainer from "../containers/global/testimonial";
import HomeAboutContainer from "../containers/home/about";
import HomeLoan from "../containers/home/loan";
import HomeBlog from "../containers/home/blog";
import HomeAboutNew from "../containers/home/about-new";
import IntroContainer from "../containers/home/intro";
import ServiceListContainer from "../containers/service/service-list";
import HomePageBanner from "../containers/global/page-banner/home-scroll";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const HomePage = () => {
    const contRef = useRef(null);
    const handleScrollToHeader = () => {
        console.log("scrllheader", contRef);
        if (contRef.current) {
            console.log("scrllheader2");
            // Adjust scrolling to account for the height of the small div
            const headerPosition = contRef.current.offsetTop;
            const divHeight = 0; // Adjust this if your div's height changes
            window.scrollTo({
                top: headerPosition,
                behavior: "smooth",
            });
        }
    };
    return (
        <React.Fragment>
            <Layout>
                <SEO title="NeoGrowth - Lending simplified. Growth amplified" />
                <div className="wrapper">
                    <Header
                        onPressSkipButton={() => {
                            handleScrollToHeader();
                        }}
                    />
                    <HomePageBanner />

                    <HomeAboutNew classOption="mb-10 pt-5" />
                    {/* <HomeAboutContainer
                        classOption="pb-10 pt-10"
                        contRef={contRef}
                    /> */}
                    <HomeLoan classOption="pb-10 pt-10" />
                    <InfoBoxContainer classOption="pb-10 pt-5 mt-lg-0" />
                    <ServiceListContainer classOption="pb-10 pt-5" />
                    <ImpactContainer classOption="mb-10 pt-5" />
                    <TestimonialContainer classOption="mb-10 pt-5" />
                    <NewsContainer classOption="mb-10 pb-10 pt-5" />
                    {/* <HomeBlog classOption="mb-10 pt-5" /> */}

                    {/* <TeamContainer classOption="section-pb pt-5" /> */}

                    {/* <IconBoxContainer classOption="pb-10 pt-5 mt-lg-0" /> */}

                    {/* <IntroContainer contRef={contRef} /> */}

                    {/* <FunFactContainer classOption="mt-10 mt-lg-0" /> */}

                    {/* <BrandContainer />                     */}
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default HomePage;
