import React from "react";
import PropTypes from "prop-types";
import Impact from "../../components/impact";

import HomeData from "../../data/impact.json";

const InsightsContainer = ({ classOption }) => {
    return (
        <div
            className={`funfact-section position-relative g-background-color  ${classOption}`}
        >
            <div className="container">
                <div className="row mb-5 ">
                    <div className="col-xl-12 col-lg-12 mx-auto text-center">
                        <div className="title-section title-section text-center mb-5">
                            <span className="sub-title"></span>
                            <h4 className="font-weight-bold g-color">
                                <b>Key Insights From The Report</b>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row mb-n7 text-center">
                    {HomeData[1].impact &&
                        HomeData[1].impact.map((single, key) => {
                            return (
                                <div key={key} className="col-md-3 col-6 mb-7">
                                    <div className="fanfact text-md-left text-center">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                single.thumb
                                            }
                                            alt="images"
                                            width={"80%"}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    <p className="pb-4">
                        Based on an impact assessment survey of 250 NeoGrowth
                        customers in 7 cities across India
                    </p>
                </div>
            </div>
        </div>
    );
};

InsightsContainer.propTypes = {
    classOption: PropTypes.string,
};

InsightsContainer.defaultProps = {
    classOption: "mt-10 mt-lg-0",
};

export default InsightsContainer;
