import { useEffect, useRef } from "react";
import Button from "../../components/button";
import SectionTitle from "../../components/section-title";
import Parallax from "parallax-js";
import data from "../../data/about_us.json";
import PropTypes from "prop-types";
import React from "react";
// import { Link } from "react-router-dom";
// import { slugify } from "../../utils";

const AboutContainer = ({ data }) => {
    return (
        <div className="about-us-page">
            {data.map((item) => (
                <div key={item.id} className="about-us-section">
                    <h2>{item.title}</h2>

                    {/* Render subtitle if available */}
                    {item.subtitle && <h3>{item.subtitle}</h3>}

                    {/* Render details if available */}
                    {item.details_1 && <p>{item.details_1}</p>}
                    {item.details_2 && <p>{item.details_2}</p>}
                    {item.details_3 && <p>{item.details_3}</p>}
                    {item.details_4 && <p>{item.details_4}</p>}
                    {item.details && <p>{item.details}</p>}

                    {/* Render link if available */}
                    {item.link && (
                        <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {item.link}
                        </a>
                    )}

                    {/* Handling nested objects like InvestorGrievances */}
                    {item.InvestorGrievances && (
                        <div className="investor-grievance">
                            <h4>Investor Grievance Contact</h4>
                            <p>Name: {item.InvestorGrievances.name}</p>
                            <p>
                                Designation:{" "}
                                {item.InvestorGrievances.designation}
                            </p>
                            <p>
                                Organization:{" "}
                                {item.InvestorGrievances.organization}
                            </p>
                            <p>Address: {item.InvestorGrievances.address}</p>
                            <p>Phone: {item.InvestorGrievances.phone}</p>
                            <p>Email: {item.InvestorGrievances.email}</p>
                        </div>
                    )}

                    {/* Handling nested arrays like Debenture Trustees */}
                    {item.DebentureTrustees && (
                        <div className="debenture-trustees">
                            <h4>Debenture Trustees</h4>
                            {item.DebentureTrustees.map((trustee) => (
                                <div key={trustee.id}>
                                    <h5>{trustee.name}</h5>
                                    <p>Designation: {trustee.designation}</p>
                                    <p>Organization: {trustee.organization}</p>
                                    <p>Address: {trustee.address}</p>
                                    <p>Phone: {trustee.phone}</p>
                                    <p>Email: {trustee.email}</p>
                                    {trustee.website && (
                                        <a
                                            href={trustee.website}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {trustee.website}
                                        </a>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Handling other nested objects like Registrar and Transfer Agent */}
                    {item.RegistrarAndTransferAgent && (
                        <div className="registrar-transfer-agent">
                            <h4>Registrar and Transfer Agent</h4>
                            <p>Name: {item.RegistrarAndTransferAgent.name}</p>
                            <p>
                                Designation:{" "}
                                {item.RegistrarAndTransferAgent.designation}
                            </p>
                            <p>
                                Organization:{" "}
                                {item.RegistrarAndTransferAgent.organization}
                            </p>
                            <p>
                                Address:{" "}
                                {item.RegistrarAndTransferAgent.address}
                            </p>
                            <p>Phone: {item.RegistrarAndTransferAgent.phone}</p>
                            <p>Email: {item.RegistrarAndTransferAgent.email}</p>
                            <a
                                href={item.RegistrarAndTransferAgent.website}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {item.RegistrarAndTransferAgent.website}
                            </a>
                        </div>
                    )}

                    {/* Handling Principal Nodal Officer */}
                    {item.PrincipalNodalOfficer && (
                        <div className="principal-nodal-officer">
                            <h4>Principal Nodal Officer</h4>
                            <p>Name: {item.PrincipalNodalOfficer.name}</p>
                            <p>
                                Designation:{" "}
                                {item.PrincipalNodalOfficer.designation}
                            </p>
                            <p>
                                Organization:{" "}
                                {item.PrincipalNodalOfficer.organization}
                            </p>
                            <p>Address: {item.PrincipalNodalOfficer.address}</p>
                            <p>Phone: {item.PrincipalNodalOfficer.phone}</p>
                            <p>Email: {item.PrincipalNodalOfficer.email}</p>
                        </div>
                    )}

                    {/* Handling Grievance Redressal Officer */}

                    {item.GrievanceRedressalOfficer && (
                        <div className="grievance-redressal-officer">
                            <h4>Grievance Redressal Officer</h4>
                            <p>Name: {item.GrievanceRedressalOfficer.name}</p>
                            <p>
                                Designation:{" "}
                                {item.GrievanceRedressalOfficer.designation}
                            </p>
                            <p>
                                Organization:{" "}
                                {item.GrievanceRedressalOfficer.organization}
                            </p>
                            <p>
                                Address:{" "}
                                {item.GrievanceRedressalOfficer.address}
                            </p>
                            <p>Phone: {item.GrievanceRedressalOfficer.phone}</p>
                            <p>Email: {item.GrievanceRedressalOfficer.email}</p>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

AboutContainer.propTypes = {
    data: PropTypes.object,
};

export default AboutContainer;
