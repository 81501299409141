import { useEffect, useRef, useContext, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontSizeContext } from "../../context/fontSizeContext";

const IconBox = ({ data }) => {
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);
    }, []);

    return (
        <div className={data.className}>
            <div className="feature-card bg-light box-shadow-gray text-center">
                <span className="card-shape card-shape-light"></span>
                <span className="card-shape card-shape-dark"></span>
                <img
                    className="logo"
                    src={process.env.PUBLIC_URL + data.icon}
                    alt=" feature logo"
                />
                <h4
                    ref={(el) => (textRefs.current[0] = el)}
                    className="title my-6"
                >
                    <Link
                        // to={process.env.PUBLIC_URL + "/service-details/1"}
                        to={process.env.PUBLIC_URL + "/loan"}
                        className="g-color"
                    >
                        {data.title}
                    </Link>
                </h4>
                <p ref={(el) => (textRefs.current[1] = el)}>{data.excerpt}</p>
            </div>
        </div>
    );
};

IconBox.propTypes = {
    data: PropTypes.object,
};

export default IconBox;
