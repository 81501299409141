import React, { useEffect, useRef, useContext, useState } from "react";
import PropTypes from "prop-types";
import { AccordionContext } from "./context";
import { FontSizeContext } from "../../context/fontSizeContext";

const AccordionTitle = ({ children, id }) => {
    const { toggleActive, activeId, setIsInit } = useContext(AccordionContext);

    const clickHandler = () => {
        if (!toggleActive || !id || !setIsInit) return;
        setIsInit(false);
        toggleActive(id);
    };

    const isActive = id === activeId ? true : false;

    // Font
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);
    }, []);
    // Font

    return (
        <div
            aria-hidden="true"
            className={`accordion-header ${isActive ? "active" : ""}`}
            type="button"
            onMouseEnter={clickHandler}
        >
            <span
                className="accordion-title"
                ref={(el) => (textRefs.current[0] = el)}
            >
                {children}
            </span>
        </div>
    );
};

AccordionTitle.propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default AccordionTitle;
