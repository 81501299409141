import PropTypes from "prop-types";
import { useEffect, useRef, useContext, useState } from "react";
import { FontSizeContext } from "../../context/fontSizeContext";

const SectionTitle = ({
    subTitle,
    title,
    excerpt,
    classOption,
    excerptClassOption,
}) => {
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);
    }, []);

    return (
        <div className={`title-section ${classOption}`}>
            <span
                ref={(el) => (textRefs.current[0] = el)}
                className="sub-title"
            >
                {subTitle}
            </span>
            <h3
                ref={(el) => (textRefs.current[1] = el)}
                className="title"
                dangerouslySetInnerHTML={{ __html: title }}
            ></h3>
            <p
                ref={(el) => (textRefs.current[2] = el)}
                className={`${excerptClassOption}`}
                dangerouslySetInnerHTML={{ __html: excerpt }}
            ></p>
        </div>
    );
};

SectionTitle.propTypes = {
    subTitle: PropTypes.string,
    title: PropTypes.string,
    excerpt: PropTypes.string,
    classOption: PropTypes.string,
    excerptClassOption: PropTypes.string,
};
SectionTitle.defaultProps = {
    classOption: "section-title",
};

export default SectionTitle;
