import { useEffect, useRef, useContext, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { slugify } from "../../utils";
import { FontSizeContext } from "../../context/fontSizeContext";

const ServiceBox = ({ data, classOption }) => {
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);
    }, []);

    return (
        <div className={`service-media ${classOption}`}>
            <div className="service-media-body footer-widget text-center">
                <h3 ref={(el) => (textRefs.current[0] = el)} className="title">
                    <a target="_blank" rel="noreferrer" href={data.oldlink}>
                        {data.title}
                    </a>
                </h3>
                {/* <p ref={(el) => (textRefs.current[1] = el)}>Collateral free</p> */}
                <p
                    ref={(el) => (textRefs.current[1] = el)}
                    className="b-color"
                    style={{ fontSize: "14px" }}
                >
                    {data.homedetail}
                </p>
                <p
                    ref={(el) => (textRefs.current[1] = el)}
                    style={{ fontSize: "14px" }}
                >
                    {data.homeExcerpt}
                </p>
            </div>
            <img
                style={{
                    marginRight: "0px",
                    borderTopLeftRadius: ".5rem",
                    borderBottomLeftRadius: ".5rem",
                }}
                className="logo"
                src={process.env.PUBLIC_URL + data.icon}
                alt=" service logo"
                width={"25%"}
            />
        </div>
    );
};

ServiceBox.propTypes = {
    data: PropTypes.object,
};

ServiceBox.propTypes = {
    classOption: PropTypes.string,
};

ServiceBox.defaultProps = {
    classOption: "section-pb",
};

export default ServiceBox;
