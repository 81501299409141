import React from "react";
import PropTypes from "prop-types";
import Impact from "../../../components/impact";
import Button from "../../../components/button";
import SectionTitle from "../../../components/section-title";

import HomeData from "../../../data/home.json";

const ImpactContainer = ({ classOption }) => {
    return (
        <div
            className={`funfact-section position-relative g-background-color  ${classOption}`}
        >
            <div className="container">
                <div className="row mb-5 ">
                    <div className="col-xl-12 col-lg-12 mx-auto text-center">
                        <SectionTitle
                            classOption="title-section text-center mb-5"
                            subTitle=""
                            title="<span class='text-primary'>NeoGrowth</span> Creating Impact"
                            excerptClassOption="null"
                            excerpt=""
                        />
                        <h5 className="happy-customer">
                            Everyone deserves a chance to grow!
                        </h5>
                    </div>
                </div>
                <div className="row mb-n7">
                    {HomeData[5].impact &&
                        HomeData[5].impact.map((single, key) => {
                            return (
                                <div key={key} className="col-md-3 col-6 mb-7">
                                    <Impact data={single} key={key} />
                                </div>
                            );
                        })}
                </div>
                <div className="col-xl-12 mb-n7 align-self-center text-center pt-10 pb-5">
                    <p>
                        *Based on impact assessment survey of NeoGrowth
                        customers
                    </p>
                    <Button
                        classOption="btn btn-xm btn-primary btn-hover-primary col-md-2"
                        text="View more"
                        target="_blank"
                        path="/impact"
                    />
                </div>
            </div>
        </div>
    );
};

ImpactContainer.propTypes = {
    classOption: PropTypes.string,
};

ImpactContainer.defaultProps = {
    classOption: "mt-10 mt-lg-0",
};

export default ImpactContainer;
