import React from "react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import HomeData from "../../data/impact.json";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ReportsHistoryContainer = ({ contRef }) => {
    SwiperCore.use([Autoplay, Navigation]);
    const swiperOption = {
        loop: true,
        rewind: true,
        navigation: true,
        speed: 2000,
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: false,
        autoplay: {
            delay: 2000,
            disableOnInteraction: true,
        },
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 2,
            },
            // when window width is >= 768px
            768: {
                slidesPerView: 2,
            },
            // when window width is >= 992px
            992: {
                slidesPerView: 3,
            },
        },
    };
    return (
        <div className="brand-section mb-10 pt-5" ref={contRef}>
            <div className="container">
                <div className="row mb-5 ">
                    <div className="col-xl-12 col-lg-12 mx-auto text-center">
                        <div className="title-section title-section text-center mb-5">
                            <span className="sub-title"></span>
                            <span className="date-meta g-color">
                                <b>Download Reports</b>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Swiper className="brand-carousel" {...swiperOption}>
                            {HomeData[5].reports &&
                                HomeData[5].reports.map((single, key) => {
                                    return (
                                        <SwiperSlide key={key}>
                                            <div className="col-9">
                                                <Link
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    to={
                                                        process.env.PUBLIC_URL +
                                                        single.link
                                                    }
                                                >
                                                    <div className="bg-primary report-box text-center">
                                                        <i className=" fa fa-download fa-lg y-color"></i>
                                                        <div className=" report-inner-box text-center mt-2">
                                                            <p className=" ">
                                                                <b>
                                                                    {
                                                                        single.name
                                                                    }
                                                                </b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
};

ReportsHistoryContainer.propTypes = {
    contRef: PropTypes.any,
};

export default ReportsHistoryContainer;
