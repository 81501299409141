import React from "react";
import PropTypes from "prop-types";

const HomePageBanner = ({ image, link }) => {
    return (
        <div className="home-banner-section position-relative">
            <a href={link} rel="noreferrer" target="_blank">
                <img className="home-banner-shape" src={image} alt="shape" />
            </a>
        </div>
    );
};

HomePageBanner.propTypes = {
    image: PropTypes.string,
    link: PropTypes.string,
};
HomePageBanner.defaultProps = {
    link: "",
};

export default HomePageBanner;
