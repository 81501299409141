import React from "react";
import PropTypes from "prop-types";
import LoanForm from "../../components/loan-form";
import ContactInfo from "../../components/contact-info";
import ContactData from "../../data/contact.json";

const LoanContainer = ({ contRef }) => {
    return (
        <section className="contact-section section-py" ref={contRef}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-8 col-lg-6 mb-7">
                        <div className="contact-title-section text-center">
                            <h3 className="title g-color">
                                Apply today for business loan
                            </h3>
                            <p className="b-color">
                                Hassle-free process
                                <br className="d-none d-xl-block" />
                            </p>
                        </div>

                        <LoanForm />
                    </div>

                    {/* <div className="col-xl-5 col-lg-6 mb-7 offset-xl-1">
                        <div className="contact-address text-center">
                            {ContactData &&
                                ContactData.map((single, key) => {
                                    return (
                                        <ContactInfo key={key} data={single} />
                                    );
                                })}
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
};

LoanContainer.propTypes = {
    contRef: PropTypes.any,
};

export default LoanContainer;
