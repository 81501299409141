import React, { useRef } from "react";
import SEO from "../../components/seo";
import Layout from "../../layouts/index";

const CorporateBrochurePage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Corporate Brochure | NeoGrowth - Lending simplified. Growth amplified" />
                <iframe
                    src={`${process.env.PUBLIC_URL}/pdf/extra/Corporate-Brochure_2024.pdf`}
                    title="a"
                    width="100%"
                    height="1080"
                ></iframe>
            </Layout>
        </React.Fragment>
    );
};

export default CorporateBrochurePage;
