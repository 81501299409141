import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";

const Button = ({ classOption, text, path, link, target }) => {
    if (path) {
        return (
            <React.Fragment>
                <Link
                    to={process.env.PUBLIC_URL + path}
                    className={`${classOption}`}
                    target={target}
                >
                    {text}
                </Link>
            </React.Fragment>
        );
    } else if (link) {
        return (
            <React.Fragment>
                <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={{
                        pathname: link,
                    }}
                    className={`${classOption}`}
                >
                    {text}
                </Link>
            </React.Fragment>
        );
    }
};

Button.propTypes = {
    classOption: PropTypes.string,
    text: PropTypes.string,
    path: PropTypes.string,
    link: PropTypes.string,
    target: PropTypes.string,
};
Button.defaultProps = {
    classOption: "btn",
    link: "",
    target: "",
};

export default Button;
