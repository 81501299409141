import PropTypes from "prop-types";
import { useState } from "react";

const Impact = ({ data }) => {
    return (
        <div className="fanfact text-md-left text-center">
            <img
                src={process.env.PUBLIC_URL + data.thumb}
                alt="images"
                width={"80%"}
            />
        </div>
    );
};

Impact.propTypes = {
    data: PropTypes.object,
};

export default Impact;
