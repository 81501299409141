import React, { useEffect, useRef, useContext, useState } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import { Link } from "react-router-dom";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import { FontSizeContext } from "../context/fontSizeContext";

const SitemapPage = () => {
    const contRef = useRef(null);
    const handleScrollToHeader = () => {
        console.log("scrllheader", contRef);
        if (contRef.current) {
            console.log("scrllheader2");
            // Adjust scrolling to account for the height of the small div
            const headerPosition = contRef.current.offsetTop;
            const divHeight = 0; // Adjust this if your div's height changes
            window.scrollTo({
                top: headerPosition,
                behavior: "smooth",
            });
        }
    };
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);
    }, []);

    return (
        <React.Fragment>
            <Layout>
                <SEO title="Sitemap | NeoGrowth - Lending simplified. Growth amplified" />
                <div className="wrapper">
                    <Header
                        onPressSkipButton={() => {
                            handleScrollToHeader();
                        }}
                    />

                    <div className="faq-section section-py m-10" ref={contRef}>
                        <hr></hr>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="accordion-style2 no-bg">
                                        <h3
                                            className="title g-color"
                                            ref={(el) =>
                                                (textRefs.current[1] = el)
                                            }
                                        >
                                            <strong>Site Map</strong>
                                        </h3>
                                        <br></br>
                                        <div className="row">
                                            <h3
                                                className="title b-color"
                                                ref={(el) =>
                                                    (textRefs.current[2] = el)
                                                }
                                            >
                                                Primary Menu
                                            </h3>
                                            <ul className="">
                                                <li
                                                    ref={(el) =>
                                                        (textRefs.current[3] =
                                                            el)
                                                    }
                                                    className="title b-color"
                                                >
                                                    <i className="fa fa-angle-right">
                                                        &nbsp;
                                                    </i>
                                                    <strong>About</strong>

                                                    <ul
                                                        className=""
                                                        style={{
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                to={{
                                                                    pathname:
                                                                        "https://ng.neogrowth.in/about-us/",
                                                                }}
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Our Story
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Board Of
                                                                Directors
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Our Partners
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Leadership Team
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Awards &
                                                                Recognitions
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Corporate
                                                                Brochure
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <ul className="mt-3">
                                                <li
                                                    ref={(el) =>
                                                        (textRefs.current[3] =
                                                            el)
                                                    }
                                                    className="title b-color"
                                                >
                                                    <i className="fa fa-angle-right">
                                                        &nbsp;
                                                    </i>
                                                    <strong>
                                                        Investor Relations
                                                    </strong>

                                                    <ul
                                                        className=""
                                                        style={{
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Annual Report
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Annual Return
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Financial
                                                                Results
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Stock Exchange
                                                                Intimation
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                RBI Disclosures
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Company Policies
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Consumer
                                                                Education
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                NeoInsights
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                News
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Composition of
                                                                Board Committees
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <ul className="mt-3">
                                                <li
                                                    ref={(el) =>
                                                        (textRefs.current[3] =
                                                            el)
                                                    }
                                                    className="title b-color"
                                                >
                                                    <i className="fa fa-angle-right">
                                                        &nbsp;
                                                    </i>
                                                    <strong>Products</strong>

                                                    <ul
                                                        className=""
                                                        style={{
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <i className="fa fa-angle-double-right">
                                                                &nbsp;
                                                            </i>
                                                            Retail Finance
                                                            <ul
                                                                className=""
                                                                style={{
                                                                    marginLeft:
                                                                        "10px",
                                                                }}
                                                            >
                                                                <li
                                                                    ref={(el) =>
                                                                        (textRefs.current[4] =
                                                                            el)
                                                                    }
                                                                    className="title b-color"
                                                                >
                                                                    <Link
                                                                        className="b-color"
                                                                        to={
                                                                            process
                                                                                .env
                                                                                .PUBLIC_URL +
                                                                            "/help"
                                                                        }
                                                                        ref={(
                                                                            el
                                                                        ) =>
                                                                            (textRefs.current[5] =
                                                                                el)
                                                                        }
                                                                    >
                                                                        <i className="fa fa-caret-right	">
                                                                            &nbsp;
                                                                        </i>
                                                                        NeoCash
                                                                    </Link>
                                                                </li>
                                                                <li
                                                                    ref={(el) =>
                                                                        (textRefs.current[4] =
                                                                            el)
                                                                    }
                                                                    className="title b-color"
                                                                >
                                                                    <Link
                                                                        className="b-color"
                                                                        to={
                                                                            process
                                                                                .env
                                                                                .PUBLIC_URL +
                                                                            "/help"
                                                                        }
                                                                        ref={(
                                                                            el
                                                                        ) =>
                                                                            (textRefs.current[5] =
                                                                                el)
                                                                        }
                                                                    >
                                                                        <i className="fa fa-caret-right	">
                                                                            &nbsp;
                                                                        </i>
                                                                        NeoCash
                                                                        Express
                                                                    </Link>
                                                                </li>
                                                                <li
                                                                    ref={(el) =>
                                                                        (textRefs.current[4] =
                                                                            el)
                                                                    }
                                                                    className="title b-color"
                                                                >
                                                                    <Link
                                                                        className="b-color"
                                                                        to={
                                                                            process
                                                                                .env
                                                                                .PUBLIC_URL +
                                                                            "/help"
                                                                        }
                                                                        ref={(
                                                                            el
                                                                        ) =>
                                                                            (textRefs.current[5] =
                                                                                el)
                                                                        }
                                                                    >
                                                                        <i className="fa fa-caret-right	">
                                                                            &nbsp;
                                                                        </i>
                                                                        NeoCash
                                                                        Insta
                                                                    </Link>
                                                                </li>
                                                                <li
                                                                    ref={(el) =>
                                                                        (textRefs.current[4] =
                                                                            el)
                                                                    }
                                                                    className="title b-color"
                                                                >
                                                                    <Link
                                                                        className="b-color"
                                                                        to={
                                                                            process
                                                                                .env
                                                                                .PUBLIC_URL +
                                                                            "/help"
                                                                        }
                                                                        ref={(
                                                                            el
                                                                        ) =>
                                                                            (textRefs.current[5] =
                                                                                el)
                                                                        }
                                                                    >
                                                                        <i className="fa fa-caret-right	">
                                                                            &nbsp;
                                                                        </i>
                                                                        Neocash
                                                                        Plus
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color mt-2"
                                                        >
                                                            <i className="fa fa-angle-double-right">
                                                                &nbsp;
                                                            </i>
                                                            Supply Chain Finance
                                                            <ul
                                                                className=""
                                                                style={{
                                                                    marginLeft:
                                                                        "10px",
                                                                }}
                                                            >
                                                                <li
                                                                    ref={(el) =>
                                                                        (textRefs.current[4] =
                                                                            el)
                                                                    }
                                                                    className="title b-color"
                                                                >
                                                                    <Link
                                                                        className="b-color"
                                                                        to={
                                                                            process
                                                                                .env
                                                                                .PUBLIC_URL +
                                                                            "/help"
                                                                        }
                                                                        ref={(
                                                                            el
                                                                        ) =>
                                                                            (textRefs.current[5] =
                                                                                el)
                                                                        }
                                                                    >
                                                                        <i className="fa fa-caret-right	">
                                                                            &nbsp;
                                                                        </i>
                                                                        Vendor
                                                                        Finance
                                                                    </Link>
                                                                </li>
                                                                <li
                                                                    ref={(el) =>
                                                                        (textRefs.current[4] =
                                                                            el)
                                                                    }
                                                                    className="title b-color"
                                                                >
                                                                    <Link
                                                                        className="b-color"
                                                                        to={
                                                                            process
                                                                                .env
                                                                                .PUBLIC_URL +
                                                                            "/help"
                                                                        }
                                                                        ref={(
                                                                            el
                                                                        ) =>
                                                                            (textRefs.current[5] =
                                                                                el)
                                                                        }
                                                                    >
                                                                        <i className="fa fa-caret-right	">
                                                                            &nbsp;
                                                                        </i>
                                                                        Accelerator
                                                                    </Link>
                                                                </li>
                                                                <li
                                                                    ref={(el) =>
                                                                        (textRefs.current[4] =
                                                                            el)
                                                                    }
                                                                    className="title b-color"
                                                                >
                                                                    <Link
                                                                        className="b-color"
                                                                        to={
                                                                            process
                                                                                .env
                                                                                .PUBLIC_URL +
                                                                            "/help"
                                                                        }
                                                                        ref={(
                                                                            el
                                                                        ) =>
                                                                            (textRefs.current[5] =
                                                                                el)
                                                                        }
                                                                    >
                                                                        <i className="fa fa-caret-right	">
                                                                            &nbsp;
                                                                        </i>
                                                                        Purchase
                                                                        Finance
                                                                    </Link>
                                                                </li>
                                                                <li
                                                                    ref={(el) =>
                                                                        (textRefs.current[4] =
                                                                            el)
                                                                    }
                                                                    className="title b-color"
                                                                >
                                                                    <Link
                                                                        className="b-color"
                                                                        to={
                                                                            process
                                                                                .env
                                                                                .PUBLIC_URL +
                                                                            "/help"
                                                                        }
                                                                        ref={(
                                                                            el
                                                                        ) =>
                                                                            (textRefs.current[5] =
                                                                                el)
                                                                        }
                                                                    >
                                                                        <i className="fa fa-caret-right	">
                                                                            &nbsp;
                                                                        </i>
                                                                        Supply
                                                                        Chain
                                                                        Plus
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <ul className="mt-3">
                                                <li
                                                    ref={(el) =>
                                                        (textRefs.current[3] =
                                                            el)
                                                    }
                                                    className="title b-color"
                                                >
                                                    <i className="fa fa-angle-right">
                                                        &nbsp;
                                                    </i>
                                                    <strong>Impact</strong>

                                                    <ul
                                                        className=""
                                                        style={{
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Impact Report
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Client Stories
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Sanjivni
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Corporate Social
                                                                Responsibility
                                                                (CSR)
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <ul className="mt-3">
                                                <li
                                                    ref={(el) =>
                                                        (textRefs.current[3] =
                                                            el)
                                                    }
                                                    className="title b-color"
                                                >
                                                    <i className="fa fa-angle-right">
                                                        &nbsp;
                                                    </i>
                                                    <strong>
                                                        Customer Services
                                                    </strong>

                                                    <ul
                                                        className=""
                                                        style={{
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Login
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Customer Support
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Service
                                                                Providers
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                FAQ
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Partner with Us
                                                            </Link>
                                                        </li>
                                                        <li
                                                            ref={(el) =>
                                                                (textRefs.current[4] =
                                                                    el)
                                                            }
                                                            className="title b-color"
                                                        >
                                                            <Link
                                                                className="b-color"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    "/help"
                                                                }
                                                                ref={(el) =>
                                                                    (textRefs.current[5] =
                                                                        el)
                                                                }
                                                            >
                                                                <i className="fa fa-angle-double-right">
                                                                    &nbsp;
                                                                </i>
                                                                Digital Lending
                                                                Apps
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default SitemapPage;
