import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import Logo from "../../logo";
import { RiArrowDownDoubleLine } from "react-icons/ri";
import { FaRightToBracket, FaHandPointUp } from "react-icons/fa6";

const MobileMenu = ({ show, onClose }) => {
    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-expand") ||
            target.classList.contains("menu-expand")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };
    return (
        <div className={`offcanvas-modal ${show ? "show" : ""}`}>
            <div className="offcanvas-dialog">
                <div className="menu-content">
                    <div className="offcanvas-header">
                        <Logo
                            classOption="offcanvas-logo d-inline-block"
                            image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                        />
                        <button
                            type="button"
                            className="btn-close"
                            onClick={onClose}
                        ></button>
                    </div>
                    <div
                        className="offcanvas-header"
                        style={{ justifyContent: "space-around" }}
                    >
                        <button
                            className="mx-2 top-button"
                            style={{
                                borderRadius: "4px",
                                padding: "1px 3px",
                            }}
                        >
                            <a
                                href="https://ng.neogrowth.in/loan-details/"
                                target="_blank"
                                rel="noreferrer"
                                className="text-white"
                            >
                                <FaHandPointUp stroke="2" />
                                &nbsp;&nbsp;Apply&nbsp;
                            </a>
                        </button>
                        <button
                            className="mx-2 top-button"
                            style={{
                                borderRadius: "4px",
                                padding: "1px 3px",
                            }}
                        >
                            <a
                                href="https://my.neogrowth.in/"
                                target="_blank"
                                rel="noreferrer"
                                className="text-white"
                            >
                                <FaRightToBracket stroke="2" />
                                &nbsp;&nbsp;Login&nbsp;
                            </a>
                        </button>
                    </div>

                    <nav id="offcanvasNav" className="offcanvas-menu">
                        <ul>
                            <li>
                                <NavLink exact to="/">
                                    Home
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"#"}>About</NavLink>
                                <span
                                    className="menu-expand"
                                    onClick={onClickHandler}
                                    aria-hidden="true"
                                ></span>
                                <ul>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://ng.neogrowth.in/about-us/",
                                            }}
                                        >
                                            Our Story
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/board-of-directors"
                                            }
                                        >
                                            Board Of Directors
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/partners"
                                            }
                                        >
                                            Our Partners
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://ng.neogrowth.in/leadership-team/",
                                            }}
                                        >
                                            Leadership Team
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/awards-recognitions/"
                                            }
                                        >
                                            Awards & Recognitions
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/corporate-brochure"
                                            }
                                        >
                                            Corporate Brochure
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <NavLink to={"#"}>Investor Relations</NavLink>
                                <span
                                    className="menu-expand"
                                    onClick={onClickHandler}
                                    aria-hidden="true"
                                ></span>
                                <ul>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/annual-report"
                                            }
                                        >
                                            Annual Report
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/annual-return"
                                            }
                                        >
                                            Annual Return
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/financial-results"
                                            }
                                        >
                                            Financial Results
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/stock-exchange-intimation"
                                            }
                                        >
                                            Stock Exchange Intimation
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/rbi-disclosures"
                                            }
                                        >
                                            RBI Disclosures
                                        </NavLink>
                                    </li>

                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/company-policies"
                                            }
                                        >
                                            Company Policies
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/consumer-education"
                                            }
                                        >
                                            Consumer Education
                                        </NavLink>
                                    </li>
                                    <li className="sub-menu-item">
                                        <NavLink
                                            className="sub-menu-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://ng.neogrowth.in/neoinsights/",
                                            }}
                                        >
                                            NeoInsights
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <NavLink to="#">Products</NavLink>
                                <span
                                    className="menu-expand"
                                    onClick={onClickHandler}
                                    aria-hidden="true"
                                ></span>
                                <ul>
                                    <li>
                                        <NavLink to="#">Retail Finance</NavLink>
                                        <span
                                            className="menu-expand"
                                            onClick={onClickHandler}
                                            aria-hidden="true"
                                        ></span>
                                        <ul>
                                            <li>
                                                <NavLink
                                                    className="sub-menu-link"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    to={{
                                                        pathname:
                                                            "https://ng.neogrowth.in/productspost/neocash/",
                                                    }}
                                                >
                                                    NeoCash
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    className="sub-menu-link"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    to={{
                                                        pathname:
                                                            "https://ng.neogrowth.in/productspost/neocash-express/",
                                                    }}
                                                >
                                                    NeoCash Express
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    className="sub-menu-link"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    to={{
                                                        pathname:
                                                            "https://ng.neogrowth.in/productspost/neocash-insta/",
                                                    }}
                                                >
                                                    NeoCash Insta
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    className="sub-menu-link"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    to={{
                                                        pathname:
                                                            "https://ng.neogrowth.in/productspost/neocash-plus/",
                                                    }}
                                                >
                                                    Neocash Plus
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <NavLink to="#">
                                            Supply Chain Finance
                                        </NavLink>
                                        <span
                                            className="menu-expand"
                                            onClick={onClickHandler}
                                            aria-hidden="true"
                                        ></span>
                                        <ul>
                                            <li className="sub-menu-item">
                                                <NavLink
                                                    className="sub-menu-link"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    to={{
                                                        pathname:
                                                            "https://ng.neogrowth.in/productspost/vendor-finance/",
                                                    }}
                                                >
                                                    Vendor Finance
                                                </NavLink>
                                            </li>
                                            <li className="sub-menu-item">
                                                <NavLink
                                                    className="sub-menu-link"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    to={{
                                                        pathname:
                                                            "https://ng.neogrowth.in/productspost/accelerator/",
                                                    }}
                                                >
                                                    Accelerator
                                                </NavLink>
                                            </li>
                                            <li className="sub-menu-item">
                                                <NavLink
                                                    className="sub-menu-link"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    to={{
                                                        pathname:
                                                            "https://ng.neogrowth.in/productspost/purchase-finance/",
                                                    }}
                                                >
                                                    Purchase Finance
                                                </NavLink>
                                            </li>
                                            <li className="sub-menu-item">
                                                <NavLink
                                                    className="sub-menu-link"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    to={{
                                                        pathname:
                                                            "https://ng.neogrowth.in/productspost/supply-chain-plus/",
                                                    }}
                                                >
                                                    Supply Chain Plus
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <NavLink to="#">Impact</NavLink>
                                <span
                                    className="menu-expand"
                                    onClick={onClickHandler}
                                    aria-hidden="true"
                                ></span>
                                <ul>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/impact"
                                            }
                                        >
                                            Impact Report
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://ng.neogrowth.in/clientstories/",
                                            }}
                                        >
                                            Client Stories
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://ng.neogrowth.in/csr/",
                                            }}
                                        >
                                            CSR
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <NavLink to={"#"}>Customer Services</NavLink>
                                <span
                                    className="menu-expand"
                                    onClick={onClickHandler}
                                    aria-hidden="true"
                                ></span>
                                <ul>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://elogin.neogrowth.in/login?service=https%3A%2F%2Fmy.neogrowth.in%2F",
                                            }}
                                        >
                                            Login
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://payments.neogrowth.in/",
                                            }}
                                        >
                                            Pay now
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={process.env.PUBLIC_URL + "/faq"}
                                        >
                                            FAQ
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://ng.neogrowth.in/customer-services/",
                                            }}
                                        >
                                            Support
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service-providers"
                                            }
                                        >
                                            Service Providers
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="#">Loan Documents</NavLink>
                                        <span
                                            className="menu-expand"
                                            onClick={onClickHandler}
                                            aria-hidden="true"
                                        ></span>
                                        <ul>
                                            <li className="sub-menu-item">
                                                <NavLink
                                                    className="sub-menu-link"
                                                    to={
                                                        process.env.PUBLIC_URL +
                                                        "/loan-agreements-without-collateral"
                                                    }
                                                >
                                                    Loan Agreements (Without
                                                    Collateral)
                                                </NavLink>
                                            </li>
                                            <li className="sub-menu-item">
                                                <NavLink
                                                    className="sub-menu-link"
                                                    to={
                                                        process.env.PUBLIC_URL +
                                                        "/loan-agreements-with-collateral"
                                                    }
                                                >
                                                    Loan Agreements (With
                                                    Collateral)
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <NavLink to="#">
                                            Digital Lending Apps
                                        </NavLink>
                                        <span
                                            className="menu-expand"
                                            onClick={onClickHandler}
                                            aria-hidden="true"
                                        ></span>
                                        <ul>
                                            <li className="sub-menu-item">
                                                <NavLink
                                                    className="sub-menu-link"
                                                    to={
                                                        process.env.PUBLIC_URL +
                                                        "/downloadneogrowthapp"
                                                    }
                                                >
                                                    NeoGrowth App
                                                </NavLink>
                                            </li>
                                            <li className="sub-menu-item">
                                                <a
                                                    href="https://insta.neogrowth.in/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="sub-menu-link text-black"
                                                >
                                                    Neocash Insta
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};

MobileMenu.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

export default MobileMenu;
