import React, { useRef } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import CallbackContainer from "../containers/callback";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const CallbackPage = () => {
    const contRef = useRef(null);
    const handleScrollToHeader = () => {
        console.log("scrllheader", contRef);
        if (contRef.current) {
            console.log("scrllheader2");
            // Adjust scrolling to account for the height of the small div
            const headerPosition = contRef.current.offsetTop;
            const divHeight = 0; // Adjust this if your div's height changes
            window.scrollTo({
                top: headerPosition,
                behavior: "smooth",
            });
        }
    };
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Call Back | NeoGrowth - Lending simplified. Growth amplified" />
                <div className="wrapper">
                    <Header
                        onPressSkipButton={() => {
                            handleScrollToHeader();
                        }}
                    />
                    <PageBanner
                        title="Request Call Back"
                        excerpt=""
                        image="/images/contact/1.png"
                    />
                    <CallbackContainer contRef={contRef} />
                    {/* <NewsletterArea /> */}
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default CallbackPage;
