import React, { useEffect, useRef, useContext, useState } from "react";
import SectionTitle from "../../components/section-title";
import Button from "../../components/button";
import ServiceBox from "../../components/service-box";
import ServiceData from "../../data/service.json";
import PropTypes from "prop-types";
import { FontSizeContext } from "../../context/fontSizeContext";

const DownloadAppContainer = ({ contRef, classOption }) => {
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);
    }, []);

    return (
        <div
            className={`service-section position-relative ${classOption}`}
            ref={contRef}
        >
            <div
                className="container p-3"
                style={{
                    borderTop: "solid darkgray",
                    borderBottom: "solid darkgray",
                }}
            >
                <div className="row mb-5 ">
                    <div className="col-xl-8 col-lg-8 mx-auto text-center">
                        <SectionTitle
                            classOption="title-section text-center mb-5"
                            subTitle=""
                            title="Download the <span class='text-primary'><a target='_blank' href='https://play.google.com/store/apps/details?id=com.ng_customer_app'>NeoGrowth App</a></span>"
                            excerptClassOption="null"
                            excerpt="Search for <span class='text-primary'><b>NeoGrowth</b></span> in the play store"
                        />
                        <Button
                            classOption="btn btn-xm btn-primary btn-hover-primary"
                            text="Download"
                            link="https://play.google.com/store/apps/details?id=com.ng_customer_app"
                        />
                    </div>
                </div>

                <div className="row mb-n7 align-items-center">
                    <div className="col-md-6 col-xl-6 mb-7">
                        <div className="service-media-img text-center">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/infographic/mobile-app.png`}
                                alt="Products"
                            />
                        </div>
                    </div>

                    <div className="col-md-6 col-xl-6 mb-7">
                        <div className="service-media-img text-center">
                            <p
                                ref={(el) => (textRefs.current[0] = el)}
                                className=""
                            >
                                Scan the QR code below
                            </p>
                            <img
                                src={`${process.env.PUBLIC_URL}/images/infographic/NeoGrowth_App_Barcode.png`}
                                alt="NeoGrowth App"
                                width="60%"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

DownloadAppContainer.propTypes = {
    classOption: PropTypes.string,
    contRef: PropTypes.string,
};

DownloadAppContainer.defaultProps = {
    classOption: "section-pb",
};

export default DownloadAppContainer;
