import React from "react";
import HomeData from "../../../data/home.json";
import SectionTitle from "../../../components/section-title";
import Button from "../../../components/button";
import BlogGrid from "./details";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const HomeAboutNew = ({ classOption }) => {
    return (
        <div className={`blog-section g-background-color ${classOption}`}>
            <div className="container">
                <div className="row mb-5 ">
                    <div className="col-xl-8 col-lg-8 mx-auto text-center">
                        <SectionTitle
                            classOption="title-section text-center mb-5"
                            subTitle=""
                            title="About <span class='text-primary'>NeoGrowth</span>"
                            excerptClassOption="null"
                            excerpt=""
                        />
                        {/* <Button
                            classOption="btn btn-xm btn-primary btn-hover-primary"
                            text="Products"
                            path="/loan"
                        /> */}
                    </div>
                </div>

                <div className="row mb-7">
                    <div className="col-12 mb-7">
                        <p className="b-color text-center">
                            NeoGrowth is a RBI registered new-age lender, with a
                            focus on Micro, Small, and Medium Enterprises
                            (MSMEs). It is a Middle Layer Non- Banking Financial
                            Company (NBFC-ML), offering a wide range of products
                            tailored to the dynamic needs of small businesses.
                            Its data science and technology-led approach enable
                            it to offer quick and hassle-free loans to MSMEs
                            across India.
                        </p>
                    </div>

                    {HomeData[8].about &&
                        HomeData[8].about.map((single, key) => {
                            return (
                                // <div
                                //     className="col-xl-4 col-lg-3 col-sm-6 mb-5"
                                //     key={key}
                                // >
                                //     <div className="sidbar-menu align-items-center pdf-div">
                                //         <ul>
                                //             <li>
                                //                 <NavLink
                                //                     activeClassName="active"
                                //                     className="padding-15 pdf-div-trans "
                                //                     to="#"
                                //                 >
                                //                     <img
                                //                         src={`${process.env.PUBLIC_URL}${single.image}`}
                                //                         alt="img"
                                //                         className="logo radius-box"
                                //                         width={"20%"}
                                //                     />

                                //                     <p className="font-14 justify-content-center">
                                //                         {single.title}
                                //                     </p>
                                //                 </NavLink>
                                //             </li>
                                //         </ul>
                                //     </div>
                                // </div>
                                <div
                                    key={key}
                                    className="col-lg-4 col-sm-6 mb-7"
                                >
                                    <BlogGrid
                                        classOption="null"
                                        key={key}
                                        data={single}
                                    />
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

HomeAboutNew.propTypes = {
    classOption: PropTypes.string,
};

HomeAboutNew.defaultProps = {
    classOption: "mt-10 mt-lg-0",
};

export default HomeAboutNew;
