import { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { FaBeer } from "react-icons/fa";
import { RiArrowDownDoubleLine } from "react-icons/ri";

const MainMenu = () => {
    const [show, setShow] = useState(false);

    const displayNestedMenu = (id) => {
        setShow((prevState) => ({
            ...prevState,
            [id]: !prevState[id], // Toggle visibility for specific id
        }));
    };

    return (
        <nav className="main-menu d-none d-lg-block">
            <ul className="d-flex">
                {/* <li>
                    <NavLink exact to="/">
                        Home
                    </NavLink>
                </li> */}
                <li>
                    <NavLink to={"#"}>About</NavLink>
                    <ul className="sub-menu">
                        <li className="sub-menu-item">
                            {/* <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/about-us"}
                            >
                                Our Story
                            </NavLink> */}
                            <Link
                                className="sub-menu-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                to={{
                                    pathname:
                                        "https://ng.neogrowth.in/about-us/",
                                }}
                            >
                                Our Story
                            </Link>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/board-of-directors"
                                }
                            >
                                Board Of Directors
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/partners"}
                            >
                                Our Partners
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            {/* <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/leadership-team"}
                            >
                                Leadership Team
                            </NavLink> */}
                            <Link
                                className="sub-menu-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                to={{
                                    pathname:
                                        "https://ng.neogrowth.in/leadership-team/",
                                }}
                            >
                                Leadership Team
                            </Link>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/awards-recognitions/"
                                }
                            >
                                Awards & Recognitions
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                to={{
                                    pathname:
                                        "https://ng.neogrowth.in/corporate-brochure/",
                                }}
                            >
                                Corporate Brochure
                            </NavLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <NavLink to={"#"}>Investor Relations</NavLink>

                    <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/annual-report"}
                            >
                                Annual Report
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/annual-return"}
                            >
                                Annual Return
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/financial-results"
                                }
                            >
                                Financial Results
                            </NavLink>
                        </li>

                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/stock-exchange-intimation"
                                }
                            >
                                Stock Exchange Intimation
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/rbi-disclosures"}
                            >
                                RBI Disclosures
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL + "/company-policies"
                                }
                            >
                                Company Policies
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/consumer-education"
                                }
                            >
                                Consumer Education
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <Link
                                className="sub-menu-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                to={{
                                    pathname:
                                        "https://ng.neogrowth.in/neoinsights/",
                                }}
                            >
                                NeoInsights
                            </Link>
                        </li>
                    </ul>
                </li>

                <li>
                    <NavLink to={"#"}>Products</NavLink>
                    <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <button
                                id="Retail Finance"
                                className="sub-menu-btn"
                                onClick={(event) => {
                                    displayNestedMenu("Retail Finance");
                                }}
                            >
                                <NavLink className="sub-menu-link" to={"#"}>
                                    Retail Finance
                                </NavLink>
                                <RiArrowDownDoubleLine
                                    size={20}
                                    strokeOpacity={4}
                                    className="align-self-center mx-4 border border-0"
                                />
                            </button>
                            {show["Retail Finance"] && (
                                <ul className="nested-menu">
                                    <li className="sub-menu-item">
                                        {/* <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/products/neocash"
                                            }
                                        >
                                            NeoCash
                                        </NavLink> */}
                                        <NavLink
                                            className="sub-menu-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://ng.neogrowth.in/productspost/neocash/",
                                            }}
                                        >
                                            NeoCash
                                        </NavLink>
                                    </li>
                                    <li className="sub-menu-item">
                                        <NavLink
                                            className="sub-menu-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://ng.neogrowth.in/productspost/neocash-express/",
                                            }}
                                        >
                                            NeoCash Express
                                        </NavLink>
                                    </li>
                                    <li className="sub-menu-item">
                                        <NavLink
                                            className="sub-menu-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://ng.neogrowth.in/productspost/neocash-insta/",
                                            }}
                                        >
                                            NeoCash Insta
                                        </NavLink>
                                    </li>
                                    <li className="sub-menu-item">
                                        <NavLink
                                            className="sub-menu-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://ng.neogrowth.in/productspost/neocash-plus/",
                                            }}
                                        >
                                            Neocash Plus
                                        </NavLink>
                                    </li>
                                </ul>
                            )}
                        </li>
                        <li className="sub-menu-item ">
                            <button
                                id="Supply Chain Finance"
                                className="sub-menu-btn"
                                onClick={(event) => {
                                    displayNestedMenu("Supply Chain Finance");
                                }}
                            >
                                <NavLink className="sub-menu-link" to="#">
                                    Supply Chain Finance
                                </NavLink>
                                <RiArrowDownDoubleLine
                                    size={20}
                                    strokeOpacity={4}
                                    className="align-self-center mx-4"
                                />
                            </button>
                            {show["Supply Chain Finance"] && (
                                <ul className="nested-menu">
                                    <li className="sub-menu-item">
                                        <NavLink
                                            className="sub-menu-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://ng.neogrowth.in/productspost/vendor-finance/",
                                            }}
                                        >
                                            Vendor Finance
                                        </NavLink>
                                    </li>
                                    <li className="sub-menu-item">
                                        <NavLink
                                            className="sub-menu-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://ng.neogrowth.in/productspost/accelerator/",
                                            }}
                                        >
                                            Accelerator
                                        </NavLink>
                                    </li>
                                    <li className="sub-menu-item">
                                        <NavLink
                                            className="sub-menu-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://ng.neogrowth.in/productspost/purchase-finance/",
                                            }}
                                        >
                                            Purchase Finance
                                        </NavLink>
                                    </li>
                                    <li className="sub-menu-item">
                                        <NavLink
                                            className="sub-menu-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://ng.neogrowth.in/productspost/supply-chain-plus/",
                                            }}
                                        >
                                            Supply Chain Plus
                                        </NavLink>
                                    </li>
                                </ul>
                            )}
                        </li>
                    </ul>
                </li>
                <li>
                    <NavLink to="#">Impact</NavLink>
                    <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/impact"}
                            >
                                Impact Report
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            {/* <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/"}
                            >
                                Client Stories
                            </NavLink> */}
                            <Link
                                className="sub-menu-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                to={{
                                    pathname:
                                        "https://ng.neogrowth.in/clientstories/",
                                }}
                            >
                                Client Stories
                            </Link>
                        </li>
                        <li className="sub-menu-item">
                            {/* <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/"}
                            >
                                Client Stories
                            </NavLink> */}
                            <Link
                                className="sub-menu-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                to={{
                                    pathname: "https://ng.neogrowth.in/csr/",
                                }}
                            >
                                CSR
                            </Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <NavLink to={"#"}>Customer Services</NavLink>
                    <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <Link
                                className="sub-menu-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                to={{
                                    pathname:
                                        "https://elogin.neogrowth.in/login?service=https%3A%2F%2Fmy.neogrowth.in%2F",
                                }}
                            >
                                Login
                            </Link>
                        </li>
                        <li className="sub-menu-item">
                            <Link
                                className="sub-menu-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                to={{
                                    pathname: "https://payments.neogrowth.in/",
                                }}
                            >
                                Pay now
                            </Link>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/faq"}
                            >
                                FAQ
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            {/* <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/"}
                            >
                                Customer Support
                            </NavLink> */}
                            <Link
                                className="sub-menu-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                to={{
                                    pathname:
                                        "https://ng.neogrowth.in/customer-services/",
                                }}
                            >
                                Support
                            </Link>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/service-providers"
                                }
                            >
                                Service Providers
                            </NavLink>
                        </li>

                        <li className="sub-menu-item ">
                            <button
                                id="Loan Documents"
                                className="sub-menu-btn"
                                onClick={(event) => {
                                    displayNestedMenu("Loan Documents");
                                }}
                            >
                                <NavLink className="sub-menu-link" to={"#"}>
                                    Loan Documents
                                </NavLink>
                                <RiArrowDownDoubleLine
                                    size={20}
                                    strokeOpacity={4}
                                    className="align-self-center mx-4"
                                />
                            </button>
                            {show["Loan Documents"] && (
                                <ul className="nested-menu">
                                    <li className="sub-menu-item">
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/loan-agreements-without-collateral"
                                            }
                                        >
                                            Loan Agreements (Without Collateral)
                                        </NavLink>
                                    </li>
                                    <li className="sub-menu-item">
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/loan-agreements-with-collateral"
                                            }
                                        >
                                            Loan Agreements (With Collateral)
                                        </NavLink>
                                    </li>
                                </ul>
                            )}
                        </li>

                        <li className="sub-menu-item ">
                            <button
                                id="Digital Lending Apps"
                                className="sub-menu-btn"
                                onClick={(event) => {
                                    displayNestedMenu("Digital Lending Apps");
                                }}
                            >
                                <NavLink className="sub-menu-link" to={"#"}>
                                    Digital Lending Apps
                                </NavLink>
                                <RiArrowDownDoubleLine
                                    size={20}
                                    strokeOpacity={4}
                                    className="align-self-center mx-4"
                                />
                            </button>
                            {show["Digital Lending Apps"] && (
                                <ul className="nested-menu">
                                    <li className="sub-menu-item">
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/downloadneogrowthapp"
                                            }
                                        >
                                            NeoGrowth App
                                        </NavLink>
                                    </li>
                                    <li className="sub-menu-item">
                                        <Link
                                            className="sub-menu-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://insta.neogrowth.in/",
                                            }}
                                        >
                                            Neocash Insta
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                    </ul>
                </li>

                {/* <li>
                    <NavLink to={process.env.PUBLIC_URL + "#"}>More</NavLink>
                    <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/loan"}
                            >
                                Loan
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/help"}
                            >
                                Help
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL + "/request-callback"
                                }
                            >
                                Request Callback
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <Link
                                className="sub-menu-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                to={{
                                    pathname: "https://payments.neogrowth.in/",
                                }}
                            >
                                Pay Now
                            </Link>
                        </li>
                        <li className="sub-menu-item">
                            <Link
                                className="sub-menu-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                to={{
                                    pathname: "https://my.neogrowth.in/",
                                }}
                            >
                                Login
                            </Link>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/downloadneogrowthapp"
                                }
                            >
                                Download NeoGrowth App
                            </NavLink>
                        </li>

                        <li className="sub-menu-item ">
                            <button
                                id="Careers"
                                className="sub-menu-btn"
                                onClick={(event) => {
                                    displayNestedMenu("Careers");
                                }}
                            >
                                <NavLink className="sub-menu-link" to={"#"}>
                                    Careers
                                </NavLink>
                                <RiArrowDownDoubleLine
                                    size={20}
                                    strokeOpacity={4}
                                    className="align-self-center mx-4"
                                />
                            </button>
                            {show["Careers"] && (
                                <ul className="nested-menu">
                                    <li className="sub-menu-item">
                                        <NavLink
                                            className="sub-menu-link"
                                            to={process.env.PUBLIC_URL + "#"}
                                        >
                                            Life at NeoGrowth
                                        </NavLink>
                                    </li>
                                    <li className="sub-menu-item">
                                        <NavLink
                                            className="sub-menu-link"
                                            to={process.env.PUBLIC_URL + "#"}
                                        >
                                            Join us
                                        </NavLink>
                                    </li>
                                    <li className="sub-menu-item">
                                        <NavLink
                                            className="sub-menu-link"
                                            to={process.env.PUBLIC_URL + "#"}
                                        >
                                            Second Career for Women
                                        </NavLink>
                                    </li>
                                </ul>
                            )}
                        </li>
                    </ul>
                </li> */}
            </ul>
        </nav>
    );
};

export default MainMenu;
