import React from "react";
import { hydrateRoot, createRoot } from "react-dom/client";
import App from "./App";

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
    const root = hydrateRoot(document.getElementById("root"));
    root.hydrate(<App />);
} else {
    const root = createRoot(document.getElementById("root"));
    root.render(<App />);
}
