import React, { useRef, useState } from "react";
import ScrollToTop from "../../components/scroll-to-top";
import SEO from "../../components/seo";
import DisclaimerContainer from "../../containers/about-us/disclaimer";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";
import Layout from "../../layouts/index";

const DisclaimerPage = () => {
    const contRef = useRef(null);
    const handleScrollToHeader = () => {
        console.log("scrllheader", contRef);
        if (contRef.current) {
            console.log("scrllheader2");
            // Adjust scrolling to account for the height of the small div
            const headerPosition = contRef.current.offsetTop;
            const divHeight = 0; // Adjust this if your div's height changes
            window.scrollTo({
                top: headerPosition,
                behavior: "smooth",
            });
        }
    };
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Disclaimer | NeoGrowth - Lending simplified. Growth amplified" />
                <div className="wrapper">
                    <Header
                        onPressSkipButton={() => {
                            handleScrollToHeader();
                        }}
                    />

                    <DisclaimerContainer
                        classOption="pb-1 section-pt"
                        contRef={contRef}
                    />

                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default DisclaimerPage;
