import { NavLink } from "react-router-dom";
import React, { useEffect, useRef, useContext, useState } from "react";
import PropTypes from "prop-types";
import { flatDeep, slugify, containsObject } from "../../../utils";
import { FontSizeContext } from "../../../context/fontSizeContext";

const ServiceCate = ({ data }) => {
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);
    }, []);

    const cats = data.map((item) => {
        return item.categories;
    });
    let singleCatArray = flatDeep(cats).filter((item) => Boolean(item));
    let categories = [];
    singleCatArray.forEach((cat) => {
        const obj = {
            title: cat.trim(),
            slug: slugify(cat),
            count: 1,
        };
        const objIndex = containsObject(obj, categories);
        if (objIndex !== -1) {
            const prevCount = categories[objIndex].count;
            categories[objIndex] = {
                title: cat.trim(),
                slug: slugify(cat),
                count: prevCount + 1,
            };
        } else {
            categories.push(obj);
        }
    });
    console.log(categories);
    return (
        <div className="sidbar-menu">
            <ul>
                {data.map((single, i) => {
                    return (
                        <li key={i}>
                            <NavLink
                                activeClassName="active"
                                to={
                                    process.env.PUBLIC_URL +
                                    `/products/${slugify(single.url)}`
                                }
                            >
                                <img
                                    src={single.smallIcon}
                                    alt="logo"
                                    width={"20%"}
                                />
                                <span
                                    ref={(el) => (textRefs.current[0 + i] = el)}
                                >
                                    {single.title}
                                </span>
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

ServiceCate.propTypes = {
    data: PropTypes.array,
};

export default ServiceCate;
