import { Fragment, useEffect, useState, useContext } from "react";
import Logo from "../../components/logo";
import MainMenu from "../../components/menu/main-menu";
import MobileMenu from "../../components/menu/mobile-menu";
import PropTypes from "prop-types";
import { FaSitemap, FaQuestionCircle } from "react-icons/fa";
import { MdAccessible } from "react-icons/md";
import {
    FaFont,
    FaPlus,
    FaMinus,
    FaIdCard,
    FaCoins,
    FaRightToBracket,
    FaHandPointUp,
} from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontSizeContext } from "../../context/fontSizeContext";

const Header = ({ onPressSkipButton }) => {
    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow((prev) => !prev);
    };
    const [scroll, setScroll] = useState(0);
    const [headerTop, setHeaderTop] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const { fontSize, increaseFontSize, decreaseFontSize, defaultFontSize } =
        useContext(FontSizeContext);

    useEffect(() => {
        const header = document.querySelector(".sticky-header");
        setHeaderTop(header.offsetTop);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = ({}) => {
        setScroll(window.scrollY);
    };

    const handleScrollToHeader = () => {
        onPressSkipButton();
    };

    return (
        <Fragment>
            <div
                style={{
                    position: "sticky",
                    backgroundColor: "#F8F8F8",
                    textAlign: "right",
                    zIndex: 555,
                    padding: 2,
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {/* <p>This is a little div above the header!</p> */}
                <div className="d-flex flex-wrap align-items-center">
                    <div className=" d-flex col-xl-6 col-lg-6">
                        <div>
                            <button
                                onClick={handleScrollToHeader}
                                className="mx-2"
                                style={{
                                    backgroundColor: "none",
                                    border: "none",

                                    border: 0,
                                    outline: 0,
                                    color: "black",
                                    fontSize: "0.78em",
                                }}
                            >
                                Skip to main content
                            </button>
                        </div>
                        <div>
                            <button
                                className="mx-2"
                                onClick={increaseFontSize}
                                style={{
                                    backgroundColor: "none",
                                    border: "none",

                                    border: 0,
                                    outline: 0,
                                    color: "black",
                                    fontSize: "0.78em",
                                }}
                            >
                                <FaFont size={12} />
                                <FaPlus size={10} stroke="2" />
                            </button>
                        </div>
                        <div>
                            <button
                                className="mx-2"
                                onClick={defaultFontSize}
                                style={{
                                    backgroundColor: "none",
                                    border: "none",

                                    border: 0,
                                    outline: 0,
                                    color: "black",
                                    fontSize: "0.78em",
                                }}
                            >
                                <FaFont size={12} />
                            </button>
                        </div>
                        <div>
                            <button
                                className="mx-2"
                                onClick={decreaseFontSize}
                                style={{
                                    backgroundColor: "none",
                                    border: "none",

                                    border: 0,
                                    outline: 0,
                                    color: "black",
                                    fontSize: "0.78em",
                                }}
                            >
                                <FaFont size={12} />
                                <FaMinus size={10} stroke="2" />
                            </button>
                        </div>

                        {/* <div className="">
                            <button
                                className="mx-2"
                                style={{
                                    backgroundColor: "none",
                                    border: "none",
                                    border: 0,
                                    outline: 0,
                                    color: "black",
                                    fontSize: "0.78em",
                                }}
                            >
                                <Link
                                    style={{
                                        color: "black",
                                    }}
                                    to={process.env.PUBLIC_URL + "/sitemap"}
                                >
                                    <FaSitemap size={20} stroke="2" />
                                </Link>
                            </button>
                        </div> */}
                        <div className="">
                            <button
                                className="mx-2"
                                style={{
                                    backgroundColor: "none",
                                    border: "none",
                                    border: 0,
                                    outline: 0,
                                    color: "black",
                                    fontSize: "0.78em",
                                }}
                            >
                                <p className="">English</p>
                            </button>
                        </div>
                    </div>
                    <div className=" d-flex col-xl-6 col-lg-6 justify-content-end item-mobile">
                        <div>
                            <button
                                className="mx-2 top-button-text"
                                style={{
                                    backgroundColor: "none",
                                    border: "none",
                                    border: 0,
                                    outline: 0,
                                    fontSize: "0.78em",
                                }}
                            >
                                <Link
                                    className="top-button-text"
                                    to={
                                        process.env.PUBLIC_URL +
                                        "/downloadneogrowthapp"
                                    }
                                >
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/icon/play_small_icon.png`}
                                        alt="img"
                                        width={"15px"}
                                    />
                                    &nbsp; Download NeoGrowth App
                                </Link>
                            </button>

                            <button
                                className="mx-2 top-button-text"
                                style={{
                                    backgroundColor: "none",
                                    border: "none",

                                    border: 0,
                                    outline: 0,
                                    fontSize: "0.78em",
                                }}
                            >
                                <a
                                    className="top-button-text"
                                    href="https://payments.neogrowth.in/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaCoins size={15} stroke="2" />
                                    &nbsp; Pay Now
                                </a>
                            </button>
                            <button
                                className="mx-2 top-button"
                                style={{
                                    outline: "",
                                    fontSize: "0.78em",
                                    borderRadius: "4px",
                                    padding: "1px 3px",
                                }}
                            >
                                <a
                                    style={{
                                        color: "white",
                                    }}
                                    href="https://ng.neogrowth.in/loan-details/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaHandPointUp size={12} stroke="2" />
                                    &nbsp;Apply
                                </a>
                            </button>
                            <button
                                className="mx-2 top-button"
                                style={{
                                    outline: "",
                                    fontSize: "0.78em",
                                    borderRadius: "4px",
                                    padding: "1px 3px",
                                }}
                            >
                                <a
                                    style={{
                                        color: "white",
                                    }}
                                    href="https://my.neogrowth.in/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaRightToBracket size={12} stroke="2" />
                                    &nbsp;Login
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <header
                className={`header-section sticky-header ${
                    scroll > headerTop ? "is-sticky" : ""
                }`}
            >
                <div className="container">
                    <div className="row align-items-center item-mobile">
                        <div className="col-xl-3 col-lg-3 col-sm-3">
                            <Logo
                                classOption="d-block"
                                classImg="logo-width"
                                image={`${process.env.PUBLIC_URL}/images/logo/logo_new.svg`}
                            />
                        </div>

                        <div className="col-xl-8 col-lg-8 col-sm-6 text-end">
                            <MainMenu />
                            <button
                                className="toggle"
                                onClick={onCanvasHandler}
                            >
                                <span className="icon-top"></span>
                                <span className="icon-middle"></span>
                                <span className="icon-bottom"></span>
                            </button>
                        </div>

                        <div className="col-xl-1 col-lg-1 col-sm-3">
                            <NavLink
                                target="_blank"
                                rel="noopener noreferrer"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/pdf/dlai/Digital_Lending_Association_Of_India.pdf"
                                }
                            >
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/images/logo/dlai.png"
                                    }
                                    alt="images_not_found"
                                />
                            </NavLink>
                        </div>
                    </div>

                    {/* Mobile Menu */}
                    <div className="row align-items-center item-desk">
                        <div className="col">
                            <Logo
                                classOption="d-block"
                                classImg="logo-width"
                                image={`${process.env.PUBLIC_URL}/images/logo/logo_new.svg`}
                            />
                        </div>

                        <div className="col text-end">
                            <MainMenu />
                            <button
                                className="toggle"
                                onClick={onCanvasHandler}
                            >
                                <span className="icon-top"></span>
                                <span className="icon-middle"></span>
                                <span className="icon-bottom"></span>
                            </button>
                        </div>
                    </div>
                    {/* Mobile Menu */}
                </div>
            </header>
            <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
        </Fragment>
    );
};

Header.propTypes = {
    onPressSkipButton: PropTypes.func,
};

export default Header;
