import React, { useEffect, useRef, useContext, useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { FontSizeContext } from "../../context/fontSizeContext";

const LawContainer = ({ data, contRef }) => {
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);
    }, []);
    return (
        <div className="single-service  pb-0 pt-10" ref={contRef}>
            <div className="container">
                <div className="row justify-content-center">
                    {data.map((single, i) => {
                        return (
                            <div
                                className="col-xl-4 col-lg-3 col-sm-6 mb-5"
                                key={i}
                            >
                                <div className="sidbar-menu align-items-center pdf-div">
                                    <ul>
                                        <li>
                                            <NavLink
                                                target="_blank"
                                                activeClassName="active"
                                                className="padding-15 pdf-div-trans "
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    single.link
                                                }
                                            >
                                                <i className="fa fa-download px-2">
                                                    &nbsp;&nbsp;
                                                </i>
                                                <p
                                                    className="font-14 justify-content-center text-center"
                                                    ref={(el) =>
                                                        (textRefs.current[i] =
                                                            el)
                                                    }
                                                >
                                                    {single.localName} <br></br>
                                                    {single.name}
                                                </p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

LawContainer.propTypes = {
    data: PropTypes.array,
    contRef: PropTypes.any,
};

export default LawContainer;
