import { useEffect, useRef, useState, useContext } from "react";
import Button from "../../../components/button";
import SectionTitle from "../../../components/section-title";
import Parallax from "parallax-js";
import PropTypes from "prop-types";
import { FontSizeContext } from "../../../context/fontSizeContext";

const HomeAboutContainer = ({ contRef, classOption }) => {
    const sceneEl = useRef(null);
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);
    const [videoSrc, setVideoSrc] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    useEffect(() => {
        if (isModalOpen) {
            setVideoSrc(
                "https://www.youtube.com/embed/oUZnCGZpLyU?si=hjVgsQtFHyANoGb6&autoplay=1"
            );
        } else {
            setVideoSrc(""); // Stop video when modal is closed
        }
    }, [isModalOpen]);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);

        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div
            className={`about-us position-relative g-background-color ${classOption}`}
            ref={contRef}
        >
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-5 col-lg-6 mb-7">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                subTitle="NeoGrowth"
                                title="RBI Registered NBFC,
                                <span class='text-primary'>
                                    Pioneer in SME lending
                                </span>
                                <br className='d-none d-xl-block' />
                                "
                            />
                            <span
                                ref={(el) => (textRefs.current[0] = el)}
                                className="date-meta"
                            >
                                Since 2013 <span className="hr"></span>
                            </span>
                            <p
                                ref={(el) => (textRefs.current[1] = el)}
                                className="mb-5"
                            >
                                NeoGrowth funds the big dreams of small
                                businesses. Small businesses are the backbone of
                                the nation & We are their backbone.
                            </p>
                            <p
                                ref={(el) => (textRefs.current[1] = el)}
                                className="high-light mb-8"
                            >
                                Everyone deserves a chance to grow, and we’ll
                                give you the freedom to grow your way.
                            </p>
                            <button
                                className="btn btn-lg btn-primary btn-hover-primary me-4"
                                type="button"
                                onClick={() => {
                                    openModal();
                                }}
                            >
                                Watch our Brand Video
                            </button>

                            <Button
                                classOption="btn btn-lg btn-dark btn-hover-dark"
                                text="About Us"
                                link="https://ng.neogrowth.in/about-us/"
                            />
                        </div>
                    </div>
                    <div className="col-xl-6 order-first order-lg-last col-lg-6 mb-7 offset-xl-1">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/banner/process.png`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <img
                className="about-shape"
                src={`${process.env.PUBLIC_URL}/images/about/shape.png`}
                alt="bg-shape"
            /> */}
            <div
                className={`modal ${isModalOpen ? "show" : ""}`}
                style={{
                    display: isModalOpen ? "block" : "none",
                    background: "#b5c4a875",
                }}
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                    style={{
                        maxWidth: "1000px",
                        overflow: "visible",
                        borderRadius: "0.75em",
                    }} // Adjust modal width
                >
                    <div
                        className="modal-content"
                        style={{ position: "relative", overflow: "visible" }}
                    >
                        {/* Close button */}
                        <button
                            type="button"
                            className="btn-close custom-close-button"
                            style={{
                                position: "absolute",
                                top: "-15px",
                                right: "-5px",
                                backgroundColor: "#fff",
                                border: "1px solid #ccc",
                                borderRadius: "50%",
                                padding: "5px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                zIndex: 1050,
                                width: "25px",
                                height: "25px",
                            }}
                            aria-label="Close"
                            onClick={closeModal}
                        ></button>

                        <div className="modal-body padding-0">
                            {videoSrc && (
                                <iframe
                                    width="100%"
                                    height="450" // Adjust height as needed
                                    src={videoSrc}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen
                                ></iframe>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

HomeAboutContainer.propTypes = {
    classOption: PropTypes.string,
    contRef: PropTypes.string,
};

HomeAboutContainer.defaultProps = {
    classOption: "section-pb",
};

export default HomeAboutContainer;
