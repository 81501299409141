import React from "react";
import SectionTitle from "../../components/section-title";
import PropTypes from "prop-types";

const DownloadAppContainer = ({ contRef, classOption }) => {
    return (
        <div
            className={`service-section position-relative ${classOption}`}
            ref={contRef}
        >
            <div
                className="container p-3"
                style={{
                    borderTop: "solid darkgray",
                    borderBottom: "solid darkgray",
                }}
            >
                <div className="row">
                    <div className="col-xl-8 col-lg-8 mx-auto text-center">
                        <SectionTitle
                            classOption="title-section text-center mb-5"
                            subTitle=""
                            title="<span class='text-primary'>Key Stats</span>"
                            excerptClassOption="null"
                            excerpt=""
                        />
                    </div>
                </div>

                <div className="row mb-n7 align-items-center">
                    <div className="col-md-12 col-xl-12 mb-9">
                        <table
                            style={{
                                width: "100%",
                                tableLayout: "fixed",
                                wordWrap: "break-word",
                            }}
                        >
                            <tbody>
                                <tr>
                                    <th style={{ textAlign: "center" }}>
                                        <h2 className="title">
                                            <span className="text-primary">
                                                <b>25K+</b>
                                            </span>
                                        </h2>
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                        <h2 className="title">
                                            <span className="text-primary">
                                                <b>4.3K+</b>
                                            </span>
                                        </h2>
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                        <h2 className="title">
                                            <span className="text-primary">
                                                <b>21</b>
                                            </span>
                                        </h2>
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                        <h2 className="title">
                                            <span className="text-primary">
                                                <b>65</b>
                                            </span>
                                        </h2>
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                        <h2 className="title">
                                            <span className="text-primary">
                                                <b>700+</b>
                                            </span>
                                        </h2>
                                    </th>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                    >
                                        Number of loans disbursed till date
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                    >
                                        INR in Crore (USD 639 Mn) mobilized
                                        through loans till 31st Mar&lsquo;19
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        NeoGrowth location across india
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                    >
                                        Different industries serviced in 2018-19
                                        alone
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                    >
                                        Employees across all locations
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

DownloadAppContainer.propTypes = {
    classOption: PropTypes.string,
    contRef: PropTypes.string,
};

DownloadAppContainer.defaultProps = {
    classOption: "section-pb",
};

export default DownloadAppContainer;
