import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../utils";
import Logo from "../logo";

const TeamInfo = ({ show, onClose }) => {
    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-expand") ||
            target.classList.contains("menu-expand")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };
    return (
        <div className={`modal ${show ? "show" : ""}`}>
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        {/* <h5 className="modal-title">Popover in a modal</h5> */}
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => onClose()}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <h5>...</h5>
                    </div>
                </div>
            </div>
        </div>
    );
};

TeamInfo.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

export default TeamInfo;
