import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavScrollTop from "./components/nav-scroll-top";
import HomePage from "./pages/index";
import AboutPage from "./pages/about";
import ServicePage from "./pages/service";
import TeamPage from "./pages/team";
import FaqPage from "./pages/faq";
import SitemapPage from "./pages/sitemap";
import BlogPage from "./pages/blog";
import ContactPage from "./pages/contact";
import LoanPage from "./pages/loan";
import HelpPage from "./pages/help";
import CallbackPage from "./pages/callback";
import BlogLeftSidebarPage from "./pages/blog-left-sidebar";
import BlogRightSidebarPage from "./pages/blog-right-sidebar";
// impact-report
import ImpactReportPage from "./pages/impact/impact-report";
// about-us
import AboutUsPage from "./pages/about-us/about-us";
import PartnersPage from "./pages/about-us/partners";
import CorporateBrochurePage from "./pages/about-us/corporate-brochure";
import DisclaimerPage from "./pages/about-us/disclaimer";
import PrivacyPolicyPage from "./pages/about-us/privacy-policy";
import DownloadApp from "./pages/about-us/download-app";
import BoardOfDirectorsPage from "./pages/about-us/board-of-directors";
import AwardsRecognitionsPage from "./pages/about-us/awards-recognitions";
import GstDetailsPage from "./pages/about-us/gst-details";
import KeyStatsPage from "./pages/about-us/key-stats";

// investor-relations
import AnnualReportPage from "./pages/investor-relations/annual-report";
import AnnualReturnPage from "./pages/investor-relations/annual-return";
import FinancialResultsPage from "./pages/investor-relations/financial-results";
import StockExchangeIntimationPage from "./pages/investor-relations/stock-exchange-intimation";
import RBIDisclosuresPage from "./pages/investor-relations/rbi-disclosures";
import CompanyPoliciesPage from "./pages/investor-relations/company-policies";
import ConsumerEducationPage from "./pages/investor-relations/consumer-education";
// customer-service
import ServiceProvidersPage from "./pages/customer-service/service-providers";
import LawocPage from "./pages/customer-service/Lawo-collateral";
import LawcPage from "./pages/customer-service/Law-collateral";

import ServiceDetails from "./templates/service-details";
import ProductsDetails from "./templates/product-details";
import BlogDetailsPage from "./templates/blog-details";
import BlogAuthor from "./templates/blog-author";
import BlogDate from "./templates/blog-date";
import BlogTag from "./templates/blog-tag";
import BlogCategory from "./templates/blog-category";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/effect-fade/effect-fade.scss";
import "swiper/components/effect-coverflow/effect-coverflow.scss";

import "./assets/css/vendor/metropolis.css";
import "./assets/css/vendor/poppins.css";
import "./assets/css/vendor/icofont.min.css";
import "./assets/css/vendor/font-awesome.css";
import "./assets/css/vendor/material-design-iconic.min.css";
import "./assets/css/plugins/animate.min.css";
import "./assets/scss/style.scss";
import "./assets/css/custom.css";
import { FontSizeProvider } from "./context/fontSizeContext";

const App = () => {
    return (
        <FontSizeProvider>
            <Router>
                <NavScrollTop>
                    <Switch>
                        <Route
                            path={`${process.env.PUBLIC_URL + "/"}`}
                            exact
                            component={HomePage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/about"}`}
                            component={AboutPage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/disclaimer"}`}
                            component={DisclaimerPage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/about-us"}`}
                            component={AboutUsPage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/service"}`}
                            component={ServicePage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/annual-report"
                            }`}
                            component={AnnualReportPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/annual-return"
                            }`}
                            component={AnnualReturnPage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/impact"}`}
                            component={ImpactReportPage}
                        />

                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/financial-results"
                            }`}
                            component={FinancialResultsPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL +
                                "/stock-exchange-intimation"
                            }`}
                            component={StockExchangeIntimationPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/rbi-disclosures"
                            }`}
                            component={RBIDisclosuresPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/company-policies"
                            }`}
                            component={CompanyPoliciesPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/consumer-education"
                            }`}
                            component={ConsumerEducationPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/service-providers"
                            }`}
                            component={ServiceProvidersPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/board-of-directors"
                            }`}
                            component={BoardOfDirectorsPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/corporate-brochure"
                            }`}
                            component={CorporateBrochurePage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/privacypolicy"
                            }`}
                            component={PrivacyPolicyPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/downloadneogrowthapp"
                            }`}
                            component={DownloadApp}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL +
                                "/loan-agreements-without-collateral"
                            }`}
                            component={LawocPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL +
                                "/loan-agreements-with-collateral"
                            }`}
                            component={LawcPage}
                        />

                        <Route
                            path={`${process.env.PUBLIC_URL + "/gst-details"}`}
                            component={GstDetailsPage}
                        />

                        <Route
                            path={`${process.env.PUBLIC_URL + "/key-stats"}`}
                            component={KeyStatsPage}
                        />

                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/service-details/:id"
                            }`}
                            component={ServiceDetails}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/products/:id"}`}
                            component={ProductsDetails}
                        />

                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/leadership-team"
                            }`}
                            component={TeamPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/awards-recognitions"
                            }`}
                            component={AwardsRecognitionsPage}
                        />

                        <Route
                            path={`${process.env.PUBLIC_URL + "/faq"}`}
                            component={FaqPage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/contact"}`}
                            component={ContactPage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/loan"}`}
                            component={LoanPage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/help"}`}
                            component={HelpPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/request-callback"
                            }`}
                            component={CallbackPage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/blog"}`}
                            component={BlogPage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/partners"}`}
                            component={PartnersPage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/sitemap"}`}
                            component={SitemapPage}
                        />

                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/blog-left-sidebar"
                            }`}
                            component={BlogLeftSidebarPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/blog-right-sidebar"
                            }`}
                            component={BlogRightSidebarPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/blog-details/:id"
                            }`}
                            component={BlogDetailsPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/author/:author"
                            }`}
                            component={BlogAuthor}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/date/:date"}`}
                            component={BlogDate}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/tag/:slug"}`}
                            component={BlogTag}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/category/:slug"
                            }`}
                            component={BlogCategory}
                        />
                    </Switch>
                </NavScrollTop>
            </Router>
        </FontSizeProvider>
    );
};

export default App;
