import React, { useEffect, useRef, useContext, useState } from "react";
import PropTypes from "prop-types";
import { FontSizeContext } from "../../context/fontSizeContext";
import SectionTitle from "../../components/section-title";

const BODContainer = ({ data, contRef }) => {
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);
    const [moreID, setMoreId] = useState(null);
    const contentRefs = useRef({});

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);
    }, []);

    useEffect(() => {
        Object.keys(contentRefs.current).forEach((key) => {
            if (contentRefs.current[key]) {
                contentRefs.current[key].style.maxHeight =
                    moreID == key
                        ? `${contentRefs.current[key].scrollHeight}px`
                        : "0px";
            }
        });
    }, [moreID, fontSize]);

    return (
        <div className="single-service pb-0 pt-10 " ref={contRef}>
            <div className="container">
                <div className="row justify-content-center m-1">
                    {data.map((single, i) => {
                        return (
                            <div
                                key={i}
                                className=" mb-8 shadow-lg mb-5 bg-white rounded"
                                style={{ borderRadius: 50 }}
                            >
                                {/* <div
                                    className="row col-xl-12 col-lg-12 col-sm-8 mb-5 p-0"
                                    key={i}
                                > */}
                                <div className="row col-xl-12 col-lg-12 col-sm-8">
                                    <div className="col-md-3 text-center p-0">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                single.thumb
                                            }
                                            style={{
                                                width: "100%",
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                                borderBottomLeftRadius:
                                                    "0.75rem",
                                                borderTopLeftRadius: "0.75rem",
                                            }}
                                            alt="about"
                                        />
                                    </div>
                                    <div className="col-md-9 p-4">
                                        <h5
                                            className="b-color pt-1"
                                            ref={(el) =>
                                                (textRefs.current[0 + i] = el)
                                            }
                                        >
                                            <b>{single.name}</b>
                                        </h5>
                                        <h6
                                            className="g-color "
                                            ref={(el) =>
                                                (textRefs.current[10 + i] = el)
                                            }
                                        >
                                            {single.designation}
                                        </h6>
                                        <p className="simpleborder"></p>
                                        <ul
                                            ref={(el) =>
                                                (textRefs.current[`50${i}`] =
                                                    el)
                                            }
                                            className="list-items-dot"
                                        >
                                            {single.details.map((detail, j) => {
                                                if (j < single.line) {
                                                    return (
                                                        <li
                                                            key={j}
                                                            className=" justify-content-center"
                                                        >
                                                            {detail}
                                                        </li>
                                                    );
                                                } else {
                                                }
                                            })}
                                        </ul>
                                        <button
                                            className="g-color border-0 box-shadow-0"
                                            style={{
                                                outline: "none",
                                                boxShadow: "none",
                                                border: "none",
                                            }}
                                            ref={(el) =>
                                                (textRefs.current[30 + i] = el)
                                            }
                                            onClick={() => {
                                                setMoreId(
                                                    moreID === i ? null : i
                                                );
                                            }}
                                        >
                                            {moreID == i ? "Less-" : "More+"}
                                        </button>
                                    </div>
                                    {/* </div> */}

                                    {/* {moreID == i && ( */}
                                    <div
                                        ref={(el) =>
                                            (contentRefs.current[i] = el)
                                        }
                                        style={{
                                            maxHeight: "0px",
                                            overflow: "hidden",
                                            transition:
                                                "max-height 0.5s ease-in-out",
                                        }}
                                    >
                                        <ul
                                            className="list-items-dot p-4"
                                            ref={(el) =>
                                                (textRefs.current[`60${i}`] =
                                                    el)
                                            }
                                        >
                                            {single.details.map((detail, j) => {
                                                if (j >= single.line) {
                                                    return (
                                                        <li
                                                            key={j}
                                                            className=" justify-content-center"
                                                        >
                                                            {detail}
                                                        </li>
                                                    );
                                                } else {
                                                }
                                            })}
                                        </ul>
                                    </div>
                                    {/* )} */}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

BODContainer.propTypes = {
    data: PropTypes.array,
    contRef: PropTypes.any,
};

export default BODContainer;
