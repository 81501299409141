import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Logo = ({ image, classOption, classImg, link, target }) => {
    return (
        <Link
            className={`${classOption}`}
            to={process.env.PUBLIC_URL + link}
            target={target}
        >
            <img
                className={`${classImg}`}
                src={process.env.PUBLIC_URL + image}
                alt="Logo"
            />
        </Link>
    );
};

Logo.propTypes = {
    image: PropTypes.string,
    classOption: PropTypes.string,
    classImg: PropTypes.string,
    link: PropTypes.string,
    target: PropTypes.string,
};

Logo.defaultProps = {
    classOption: "text-center",
    link: "/",
    target: "",
};

export default Logo;
