import React, { useRef, useState } from "react";
import ScrollToTop from "../../components/scroll-to-top";
import SEO from "../../components/seo";
import ImpactContainer from "../../containers/impact/insight";
import ReportsHistoryContainer from "../../containers/impact/reports-history";
import HomeAboutContainer from "../../containers/impact/info";
import HomePageBanner from "../../containers/global/page-banner/home";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";
import Layout from "../../layouts/index";

const ImpactReportPage = () => {
    const contRef = useRef(null);
    const handleScrollToHeader = () => {
        console.log("scrllheader", contRef);
        if (contRef.current) {
            console.log("scrllheader2");
            // Adjust scrolling to account for the height of the small div
            const headerPosition = contRef.current.offsetTop;
            const divHeight = 0; // Adjust this if your div's height changes
            window.scrollTo({
                top: headerPosition,
                behavior: "smooth",
            });
        }
    };
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Impact Report - Lending simplified. Growth amplified" />
                <div className="wrapper">
                    <Header
                        onPressSkipButton={() => {
                            handleScrollToHeader();
                        }}
                    />
                    <div className="home-banner-section position-relative">
                        <a
                            href="/pdf/impact/Social_Impact_Report_2024.pdf"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <img
                                style={{
                                    boxShadow:
                                        "0px 0px 5px 1px rgb(29 165 183)",
                                }}
                                className="home-banner-shape"
                                src="/images/impact-report/social-impact.jpg"
                                alt="shape"
                            />
                        </a>
                    </div>

                    <HomeAboutContainer
                        classOption="pb-10 pt-10"
                        contRef={contRef}
                    />

                    <ImpactContainer classOption="mb-10 pt-5" />
                    <ReportsHistoryContainer classOption="mb-10 pt-5" />
                    <hr></hr>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ImpactReportPage;
