import React, { useEffect, useRef, useContext, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/logo";
import SocialIcon from "../../components/social-icon";
import CompanyPoliciesData from "../../data/company-policies.json";
import { FontSizeContext } from "../../context/fontSizeContext";
import { BiLock } from "react-icons/bi";

const Footer = () => {
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);
    }, []);

    return (
        <footer
            className="footer-section pt-4"
            style={{
                backgroundImage:
                    "linear-gradient(to top, rgba(255, 0, 0, 0), rgb(227 227 227))",
            }}
        >
            <div
                className="p-7 position-relative"
                style={{
                    fontSize: "12px",
                }}
            >
                {/* <img
                    className="footer-shape"
                    src={process.env.PUBLIC_URL + "/images/footer/1.png"}
                    alt="shape"
                /> */}
                <div className="container">
                    <div className="row mb-n7">
                        <div className="col-lg-5 col-sm-6 mb-7">
                            <div className="footer-widget">
                                <Logo
                                    classOption="footer-logo mb-5"
                                    classImg="logo-width"
                                    image={`${process.env.PUBLIC_URL}/images/logo/logo_new.svg`}
                                />
                                <p
                                    className="text-dark"
                                    ref={(el) => (textRefs.current[0] = el)}
                                >
                                    NeoGrowth Credit Pvt. Ltd is a Middle Layer,
                                    Non-Deposit taking RBI Registered NBFC and a
                                    pioneer in SME lending based on the
                                    underwriting of digital payments data. We
                                    provide finance to small business owners to
                                    drive business growth that matches their
                                    ambitions.
                                </p>
                                <p
                                    className="text-dark"
                                    ref={(el) => (textRefs.current[1] = el)}
                                >
                                    Our hassle-free loans are powered by
                                    technology innovations and leverage the
                                    digital payments ecosystem. Our financing
                                    not only helps our customers grow but also
                                    creates a positive impact.
                                </p>
                                <p
                                    className="text-dark"
                                    ref={(el) => (textRefs.current[2] = el)}
                                >
                                    <strong> Registered/Head Office:</strong>{" "}
                                    Times Square, Tower E, 9th Floor,
                                    Andheri-Kurla Road, Marol, Andheri East,
                                    Mumbai- 400059, Maharashtra
                                </p>
                                <p
                                    className="text-dark"
                                    ref={(el) => (textRefs.current[3] = el)}
                                >
                                    <strong> CIN:</strong> U51504MH1993PTC251544
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 mb-7">
                            <div className="footer-widget">
                                <h4
                                    ref={(el) => (textRefs.current[4] = el)}
                                    className="title"
                                >
                                    Quick Links
                                </h4>
                                <ul className="footer-menu">
                                    {/* <li
                                        ref={(el) => (textRefs.current[5] = el)}
                                    >
                                        <Link
                                            className="footer-link "
                                            to={
                                                process.env.PUBLIC_URL + "/help"
                                            }
                                        >
                                            News
                                        </Link>
                                    </li> */}
                                    <li
                                        ref={(el) => (textRefs.current[6] = el)}
                                    >
                                        <Link
                                            className="footer-link "
                                            target="_blank"
                                            rel="noreferrer"
                                            to={process.env.PUBLIC_URL + "/faq"}
                                        >
                                            FAQs
                                        </Link>
                                    </li>
                                    <li
                                        ref={(el) => (textRefs.current[6] = el)}
                                    >
                                        <a
                                            ref={(el) =>
                                                (textRefs.current[24] = el)
                                            }
                                            className="address-link b-color"
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://ng.neogrowth.in/careers/"
                                        >
                                            Careers
                                        </a>
                                    </li>
                                    <li
                                        ref={(el) => (textRefs.current[6] = el)}
                                    >
                                        <a
                                            ref={(el) =>
                                                (textRefs.current[24] = el)
                                            }
                                            className="address-link b-color"
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://ng.neogrowth.in/news/"
                                        >
                                            News
                                        </a>
                                    </li>

                                    <li
                                        ref={(el) => (textRefs.current[9] = el)}
                                    >
                                        <a
                                            ref={(el) =>
                                                (textRefs.current[24] = el)
                                            }
                                            className="address-link b-color"
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://ng.neogrowth.in/neogrowth-blog/"
                                        >
                                            Blogs
                                        </a>
                                    </li>
                                    <li
                                        ref={(el) => (textRefs.current[8] = el)}
                                    >
                                        <Link
                                            className="footer-link "
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/gst-details"
                                            }
                                        >
                                            GST Details
                                        </Link>
                                    </li>
                                    <li
                                        ref={(el) => (textRefs.current[9] = el)}
                                    >
                                        <a
                                            ref={(el) =>
                                                (textRefs.current[24] = el)
                                            }
                                            className="address-link b-color"
                                            href="mailto:digital@neogrowth.in"
                                        >
                                            Sales Enquiry
                                        </a>
                                    </li>
                                    <li
                                        ref={(el) =>
                                            (textRefs.current[10] = el)
                                        }
                                    >
                                        <Link
                                            className="footer-link "
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/key-stats"
                                            }
                                        >
                                            Key Statistics
                                        </Link>
                                    </li>
                                    <li
                                        ref={(el) =>
                                            (textRefs.current[11] = el)
                                        }
                                    >
                                        <Link
                                            className="footer-link "
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/pdf/dlai/Digital_Lending_Association_Of_India.pdf"
                                            }
                                        >
                                            DLAI Code Of Conduct
                                        </Link>
                                    </li>
                                    <li
                                        ref={(el) =>
                                            (textRefs.current[12] = el)
                                        }
                                    >
                                        <Link
                                            className="footer-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/pdf/extra/SARFAESI-Act-2002.pdf"
                                            }
                                        >
                                            Sarfaesi Notice
                                        </Link>
                                    </li>
                                    <li
                                        ref={(el) =>
                                            (textRefs.current[13] = el)
                                        }
                                    >
                                        <Link
                                            className="footer-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://sachet.rbi.org.in/InvestorAwareness/Investor",
                                            }}
                                        >
                                            Sachet
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 mb-7">
                            <div className="footer-widget mb-7">
                                <h4
                                    ref={(el) => (textRefs.current[14] = el)}
                                    className="title"
                                >
                                    Policy
                                </h4>
                                <ul className="footer-menu">
                                    <li
                                        ref={(el) =>
                                            (textRefs.current[15] = el)
                                        }
                                    >
                                        <Link
                                            className="footer-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/disclaimer"
                                            }
                                        >
                                            Disclaimer
                                        </Link>
                                    </li>
                                    <li
                                        ref={(el) =>
                                            (textRefs.current[16] = el)
                                        }
                                    >
                                        <Link
                                            className="footer-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/pdf/extra/Interest-Rate-Policy.pdf"
                                            }
                                        >
                                            Interest Rate Policy
                                        </Link>
                                    </li>
                                    <li
                                        ref={(el) =>
                                            (textRefs.current[17] = el)
                                        }
                                    >
                                        <Link
                                            className="footer-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/privacypolicy"
                                            }
                                        >
                                            Privacy Policy
                                        </Link>
                                    </li>
                                    <li
                                        ref={(el) =>
                                            (textRefs.current[18] = el)
                                        }
                                    >
                                        <Link
                                            className="footer-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/pdf/extra/Website_T&C_(v-2).pdf"
                                            }
                                        >
                                            Terms and Conditions
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-widget ">
                                <h4
                                    ref={(el) => (textRefs.current[19] = el)}
                                    className="title"
                                >
                                    Calculators
                                </h4>
                                <ul className="footer-menu">
                                    <li
                                        ref={(el) =>
                                            (textRefs.current[20] = el)
                                        }
                                    >
                                        <Link
                                            className="footer-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://ng.neogrowth.in/loan-calculator/",
                                            }}
                                        >
                                            EMI Calculator
                                        </Link>
                                    </li>
                                    <li
                                        ref={(el) =>
                                            (textRefs.current[21] = el)
                                        }
                                    >
                                        <Link
                                            className="footer-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://calculator.neogrowth.in/GST-Calculator",
                                            }}
                                        >
                                            GST Calculator
                                        </Link>
                                    </li>
                                    <li
                                        ref={(el) =>
                                            (textRefs.current[22] = el)
                                        }
                                    >
                                        <Link
                                            className="footer-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname:
                                                    "https://calculator.neogrowth.in/Gratuity-Calculator",
                                            }}
                                        >
                                            Gratuity Calculator
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mb-7">
                            <div className="footer-widget">
                                <h4
                                    ref={(el) => (textRefs.current[23] = el)}
                                    className="title"
                                >
                                    Let&apos;s Connect
                                </h4>
                                {/* <p ref={(el) => (textRefs.current[16] = el)}>
                                    245 Southern Street, Apt. 147 New York, USA
                                </p> */}
                                <span>Mail us</span>
                                <ul className="address">
                                    <li className="mb-2">
                                        <a
                                            ref={(el) =>
                                                (textRefs.current[24] = el)
                                            }
                                            className="address-link"
                                            href="mailto:helpdesk@neogrowth.in"
                                        >
                                            <i className="fa fa-envelope">
                                                &nbsp;&nbsp;
                                            </i>
                                            helpdesk@neogrowth.in
                                        </a>
                                    </li>
                                    <span>Call us</span>
                                    <li>
                                        <a
                                            ref={(el) =>
                                                (textRefs.current[25] = el)
                                            }
                                            className="address-link"
                                            href="tel:+18004195565"
                                        >
                                            <i className="fa fa-phone-square">
                                                &nbsp;&nbsp;
                                            </i>
                                            18004195565
                                        </a>
                                    </li>
                                    <li className="mb-2">
                                        <a
                                            ref={(el) =>
                                                (textRefs.current[26] = el)
                                            }
                                            className="address-link "
                                            href="tel:+919820655655"
                                        >
                                            <i className="fa fa-phone-square">
                                                &nbsp;&nbsp;
                                            </i>
                                            +91-9820655655
                                        </a>
                                    </li>
                                </ul>
                                <ul className="footer-social-links mb-2">
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://www.facebook.com/NeoGrowth-Credit-1663092290644997/"
                                            icon=" fa fa-facebook-square fa-lg"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://in.linkedin.com/company/neogrowth-credit-pvt-ltd"
                                            icon="fa fa-linkedin-square fa-lg"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://twitter.com/NeoGrowth_Loans"
                                            icon="x"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://www.youtube.com/channel/UC_ybb6PgsDpTDGMjM9MBwPA"
                                            icon=" fa fa-youtube-square fa-lg"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://www.instagram.com/neogrowth_credit/?hl=en"
                                            icon=" fa fa-instagram fa-lg"
                                        />
                                    </li>
                                </ul>
                                <span>Download App</span>
                                <ul>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/downloadneogrowthapp"
                                            }
                                        >
                                            <Logo
                                                classOption="footer-logo mb-9"
                                                classImg="logo-width"
                                                link={
                                                    process.env.PUBLIC_URL +
                                                    "/downloadneogrowthapp"
                                                }
                                                target="_blank"
                                                image={`${process.env.PUBLIC_URL}/images/footer/google-play-badge-footer.png`}
                                            />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-info text-center">
                                <p ref={(el) => (textRefs.current[27] = el)}>
                                    Copyright &copy; 2025 <b>NeoGrowth</b>, All
                                    Rights Reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
