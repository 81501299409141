import React, { useEffect, useRef, useContext, useState } from "react";
import PropTypes from "prop-types";
import { FontSizeContext } from "../../context/fontSizeContext";

const PageTitle = ({ title, excerpt }) => {
    const { fontSize } = useContext(FontSizeContext);
    const textRefs = useRef([]);
    const [defaultSizes, setDefaultSizes] = useState([]);

    const adjustFontSize = (change) => {
        textRefs.current.forEach((element, index) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                const currentSize = parseFloat(computedStyle.fontSize);
                const newSize = currentSize + change;
                element.style.fontSize = `${newSize}px`;
            }
        });
    };

    const resetFontSize = () => {
        textRefs.current.forEach((element, index) => {
            if (element && defaultSizes[index] !== null) {
                element.style.fontSize = `${defaultSizes[index]}px`;
            }
        });
    };

    useEffect(() => {
        console.log("fontSize", fontSize);
        if (fontSize == 0) {
            resetFontSize();
        } else {
            adjustFontSize(fontSize);
        }
    }, [fontSize]);

    useEffect(() => {
        const sizes = textRefs.current.map((element) => {
            if (element) {
                const computedStyle = window.getComputedStyle(element);
                return parseFloat(computedStyle.fontSize);
            }
            return null;
        });
        setDefaultSizes(sizes);
    }, []);
    return (
        <div className="banner-content banner-padding">
            <h3 ref={(el) => (textRefs.current[0] = el)} className="title">
                {title}
            </h3>
            <p
                ref={(el) => (textRefs.current[1] = el)}
                className="b-color"
                dangerouslySetInnerHTML={{ __html: excerpt }}
            ></p>
        </div>
    );
};

PageTitle.propTypes = {
    title: PropTypes.string,
    excerpt: PropTypes.string,
};

export default PageTitle;
