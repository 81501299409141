import React from "react";
import AccordionWrap from "../../components/accrodion/AccordionWrap";
import PropTypes from "prop-types";

const AccordionContainer = ({ contRef }) => {
    return (
        <div className="faq-section pt-10" ref={contRef}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <AccordionWrap />
                    </div>
                </div>
            </div>
        </div>
    );
};

AccordionContainer.propTypes = {
    contRef: PropTypes.any,
};
export default AccordionContainer;
