import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CallbackForm = () => {
    const {
        register,
        handleSubmit,
        setValue,
        trigger,
        watch,
        formState: { errors, isValid },
    } = useForm({
        mode: "onChange", // Validates onChange
    });

    const [sheduleCall, setSheduleCall] = useState(true);
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedTime, setSelectedTime] = useState("");

    const handleRadioChange = (event) => {
        setSheduleCall(event.target.id === "sheduleCall");
    };

    const onSubmit = (data) => {
        console.log("Form Submitted:", data);
        alert("Form submitted successfully!");
    };

    return (
        <Fragment>
            <form
                id="contactForm"
                className="row"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className={`form-control ${
                            errors.name ? "is-invalid" : ""
                        }`}
                        placeholder="Your Full Name*"
                        {...register("name", {
                            required: "Name is required",
                            minLength: {
                                value: 3,
                                message:
                                    "Name must be at least 3 characters long",
                            },
                        })}
                    />
                    {errors.name && (
                        <p className="text-danger">{errors.name.message}</p>
                    )}
                </div>

                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className={`form-control ${
                            errors.phone ? "is-invalid" : ""
                        }`}
                        placeholder="Your Phone Number*"
                        {...register("phone", {
                            required: "Phone number is required",
                            pattern: {
                                value: /^[0-9]{10}$/,
                                message: "Phone number must be 10 digits",
                            },
                        })}
                    />
                    {errors.phone && (
                        <span className="text-danger">
                            {errors.phone.message}
                        </span>
                    )}
                </div>

                <div className="col-12 col-sm-12 mb-7">
                    <input
                        type="email"
                        className={`form-control ${
                            errors.email ? "is-invalid" : ""
                        }`}
                        placeholder="Your Email*"
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address",
                            },
                        })}
                    />
                    {errors.email && (
                        <span className="text-danger">
                            {errors.email.message}
                        </span>
                    )}
                </div>

                <div className="row col-12 col-sm-12 mb-7">
                    {/* <p>Are you an existing Customer?</p> */}
                    <div className="col-md-6">
                        <input
                            type="radio"
                            id="oneHourCall"
                            {...register("digitalPayment", {
                                required: "Please select an option",
                            })}
                            className="custom-control-input "
                            style={{
                                transform: "scale(1.4)",
                                accentColor: "green",
                            }}
                            onChange={handleRadioChange}
                        />
                        <label htmlFor="oneHourCall" className="ps-2">
                            1-hour Call Back
                        </label>
                    </div>
                    <div className="col-md-6 ">
                        <input
                            type="radio"
                            id="sheduleCall"
                            {...register("digitalPayment", {
                                required: "Please select an option",
                            })}
                            className="custom-control-input"
                            style={{
                                transform: "scale(1.4)",
                                accentColor: "green",
                            }}
                            onChange={handleRadioChange}
                        />
                        <label htmlFor="sheduleCall" className="ps-2">
                            Schedule a Call Back
                        </label>
                    </div>
                    {errors.digitalPayment && (
                        <span className="text-danger">
                            {errors.digitalPayment.message}
                        </span>
                    )}
                </div>

                {sheduleCall && (
                    <div className="row col-12 mx-0 px-0">
                        {/* Select Date */}
                        <div className="row col-12 col-md-6 mb-7 mx-0 px-0">
                            {/* <label className="form-label" htmlFor="selectDate">Select Date</label> */}
                            <DatePicker
                                id="selectDate"
                                selected={selectedDate}
                                className={`form-control ${
                                    errors.date ? "is-invalid" : ""
                                } w-100`}
                                onChange={(date) => {
                                    setValue("date", date); // Update react-hook-form's value
                                    trigger("date"); // Trigger validation
                                    setSelectedDate(date);
                                }}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="Select a date"
                            />
                            {/* {errors.date && <p className="text-danger">{errors.date.message}</p>} */}
                        </div>

                        {/* Select Time */}
                        <div className="row col-12 col-md-6 mb-7 mx-0 px-0">
                            <DatePicker
                                id="time"
                                selected={selectedTime}
                                onChange={(time) => {
                                    setValue("time", time); // Update react-hook-form's value
                                    trigger("time"); // Trigger validation
                                    setSelectedTime(time);
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                placeholderText="Select a time"
                                className={`form-control ${
                                    errors.time ? "is-invalid" : ""
                                }`}
                            />
                            {/* {errors.time && <p className="text-danger">{errors.time.message}</p>} */}
                        </div>
                    </div>
                )}

                <div className="col-12 col-md-12 col-sm-12">
                    <input
                        type="hidden"
                        {...register("date", { required: "Date is required" })}
                    />
                    <input
                        type="hidden"
                        {...register("time", { required: "Time is required" })}
                    />
                </div>

                <div className="col-12 col-sm-12 mb-7">
                    <ReCAPTCHA
                        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                        // onChange={onChange}
                    />
                </div>

                <div className="col-12">
                    <button
                        type="submit"
                        className={`btn btn-dark btn-hover-dark ${
                            !isValid ? "disabled" : ""
                        }`}
                    >
                        Submit
                    </button>
                </div>
            </form>
        </Fragment>
    );
};

export default CallbackForm;
